import 'firebase/firestore';
import { useEffect, useMemo, useState } from 'react';

import { myFirebase } from '../../config/firebaseConfig';
import { UserId, UserInfo } from '../../model';
import { getCache, setCache } from '../../utilities/CacheController';

const db = myFirebase.firestore();

type UseUserInfoHook = {
  userInfo: UserInfo;
  buildDocTree: () => Promise<void>;
};

const DEFAULT_USERINFO_STATE: UserInfo = {
  displayName: '',
  email: '',
  role: '',
};

const USERINFO_CACHE_KEY = 'userinfo_cache';

export const useUserInfo = (userId: UserId): UseUserInfoHook => {
  const [dataReady, setDataReady] = useState<boolean>(false);
  const [data, setData] = useState<UserInfo>(DEFAULT_USERINFO_STATE);

  const buildDocTree = async () => {
    //Attempt to load cache first, and proceed to pulling from firebase if not present
    const cachedValue = await getCache<UserInfo>(USERINFO_CACHE_KEY);

    if (cachedValue != null) {
      setData(cachedValue);
      setDataReady(true);
      return;
    }

    const userDocRef = db.collection('users').doc(userId);
    const userDocs = await userDocRef.get();

    const userInfoData = userDocs.data() as UserInfo;

    setData(userInfoData);
    setDataReady(true);
    await setCache<UserInfo>(userInfoData, USERINFO_CACHE_KEY);
  };

  // onMount, build the doctree (this happens once per 'reload or refresh')
  useEffect(() => {
    buildDocTree();
  }, []);

  const hook: UseUserInfoHook = {
    userInfo: data,
    buildDocTree: buildDocTree,
  };

  return useMemo(() => hook, [dataReady]);
};

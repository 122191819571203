import 'firebase/firestore';
import { useMemo, useState } from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { myFirebase } from '../config/firebaseConfig';
import { Exercise, exerciseCollection } from '../model';

const db = myFirebase.firestore();

export type UseExercisesHook = {
  exerciseData: exerciseCollection | undefined;
  isExerciseError: Error | undefined;
  exerciseErrorMessage: string;
  clearError: () => void;
  createExercise: (exercise: any) => Promise<void>;
  updateExercise: (exercise: any, id: any) => Promise<void>;
  doDeleteMedia: (fileName: string, type: string) => Promise<void>;
  isDeleted: boolean;
  isCompleted: boolean;

  getTumbnailURL: (thumbnail: string) => Promise<void>;
  thumbnailURL: string;
  getVideoURL: (video: string) => Promise<void>;
  videoURL: string;
  setStatusInactive: (exercise: Exercise) => Promise<void>;
  exerciseDatabaseLoading: boolean;
};

export const useExercises = (userId: string | undefined): UseExercisesHook => {
  const [isExerciseError, setIsError] = useState<Error>();
  const [isCompleted, setIsCompleted] = useState<boolean>(false);
  const [isDeleted, setIsDeleted] = useState<boolean>(false);
  const [exerciseErrorMessage, setexerciseErrorMessage] = useState<string>('');
  const [thumbnailURL, setThumbnailURL] = useState<string>('');
  const [videoURL, setVideoURL] = useState<string>('');
  // const [loadingExercises, setIsLoading] = useState<boolean>(false);

  const exercisesRef = db.collection('exercises').where('status', '==', 'active');
  // const exerciseDatabase = await exercisesRef.get();
  const options = { idField: 'id', snapshotListenOptions: { includeMetadataChanges: true } };
  const [exerciseDatabase, exerciseDatabaseLoading, exerciseDatabaseError] = useCollectionData<Exercise>(
    exercisesRef,
    options,
  );

  const convertExerciseDatabase = () => {
    const exerciseCollectionData: exerciseCollection = {};

    if (exerciseDatabase) {
      exerciseDatabase.forEach((snap) => {
        const exercise = snap;
        exerciseCollectionData[snap.id] = {
          ...exercise,
        };
      });
      return exerciseCollectionData;
      // return exerciseCollectionData;
      // const helper = exerciseCollectionData;
      // setExerciseData(helper);
    } else {
      return exerciseCollectionData;
    }
  };

  if (exerciseDatabaseError) {
    setIsError(exerciseDatabaseError);
  }

  // if (exerciseDatabaseLoading) {
  //   console.log('loading exercises...');
  //   setIsLoading(true);
  // }

  const createExercise = async (exercise: any) => {
    // const exerciseData = {
    //   Name: 'Hip Thrust',
    //   videoURL: 'https://www.youtube.com/watch?v=QX4Z3JQ3GJg',
    //   thumbnailURL: 'https://i.ytimg.com/vi/QX4Z3JQ3GJg/hqdefault.jpg',
    //   Description:
    //     'The hip thrust is a compound exercise that targets the glutes and hamstrings. It is a great exercise to build strength and size in the glutes. It is also a great exercise to build strength in the hamstrings and lower back. The hip thrust is a great exercise to build strength and size in the glutes. It is also a great exercise to build strength in the hamstrings and lower back.',
    //   Equipment: 'Barbell',
    //   Type: 'Strength',
    //   Level: 1,
    //   Complexity: 'Easy',
    //   Intention: 'lorem ipsum',
    //   CoachingCueus: ['lorem ipsum', 'lorem ipsum', 'lorem ipsum'],
    //   status: 'active',
    //   dateCreated: new Date(),
    //   createdBy: userId,
    //   lastUpdated: new Date(),
    //   lastUpdatedBy: userId,
    // };
    db.collection('exercises')
      .add({
        //destructuring the object
        ...exercise,
        dateCreated: myFirebase.firestore.Timestamp.fromDate(new Date()),
        lastUpdated: myFirebase.firestore.Timestamp.fromDate(new Date()),
        createdBy: userId,
        lastUpdatedBy: userId,
      })
      .then((docRef) => {
        setIsCompleted(true);
        console.log('Document written with ID: ', docRef.id);
      })
      .catch((error) => {
        console.error('Error adding document: ', error);
        setIsError(error);
        setexerciseErrorMessage(error);
      });
  };

  const updateExercise = async (exercise: any, id: any) => {
    console.log('exercise ID: ', exercise.id);
    const exerciseRef = db.collection('exercises').doc(id);
    exerciseRef
      .update({
        ...exercise,
        lastUpdated: myFirebase.firestore.Timestamp.fromDate(new Date()),
        lastUpdatedBy: userId,
      })
      .then((_doc) => {
        console.log('Document successfully updated!');
        setIsCompleted(true);
      })
      .catch((error) => {
        setIsError(error);
        setexerciseErrorMessage(error.message);
        console.log(error);
      });
  };

  const getTumbnailURL = async (thumbnail: string) => {
    if (!thumbnail || thumbnail === '' || thumbnail === 'undefined' || thumbnail === 'null') {
      setThumbnailURL('https://ionicframework.com/docs/img/demos/thumbnail.svg');
      return;
    }
    const storageRef = myFirebase.storage().ref();
    const thumbnailsRef = storageRef.child('thumbnails');
    const fileRef = thumbnailsRef.child(thumbnail);
    const url = await fileRef.getDownloadURL();
    setThumbnailURL(url);
  };

  const getVideoURL = async (video: string) => {
    if (!video || video === '' || video === 'undefined' || video === 'null') {
      setVideoURL('https://ionicframework.com/docs/img/demos/thumbnail.svg');
      return;
    }
    const storageRef = myFirebase.storage().ref();
    const videosRef = storageRef.child('videos');
    const fileRef = videosRef.child(video);
    const url = await fileRef.getDownloadURL();
    setVideoURL(url);
  };

  const setStatusInactive = async (exercise: Exercise) => {
    const exerciseRef = await db.collection('exercises').doc(exercise.id);
    const thumb = exercise.thumbnailURL;
    const video = exercise.videoURL;
    exerciseRef
      .update({ status: 'inactive', thumbnailURL: '', videoURL: '' })
      .then((_doc) => {
        console.log('Document successfully updated!');
        doDeleteMedia(thumb, 'image');
        doDeleteMedia(video, 'video');
      })
      .catch((error) => {
        console.log(error);
      });
    // try {
    //   doDeleteMedia(exercise.thumbnailURL, 'image');
    //   doDeleteMedia(exercise.videoURL, 'video');
    // } catch (error) {
    //   console.log(error);
    //   return;
    // }
  };

  const doDeleteMedia = async (fileName: string, type: string) => {
    console.log('deleting file: ', fileName, type);
    console.log(fileName);
    if (fileName === '' || fileName === 'undefined' || fileName === 'null') {
      return;
    }

    if (type === 'video') {
      const storageRef = myFirebase.storage().ref('videos');
      try {
        const fileRef = storageRef.child(fileName);
        fileRef
          .delete()
          .then(() => {
            console.log('file deleted successfully');
            setIsDeleted(true);
          })
          .catch((error) => {
            setIsError(error);
            setexerciseErrorMessage(error.message);
            console.log('error deleting file: ', error);
          });
      } catch (error) {
        return;
      }
    } else if (type === 'image') {
      const storageRef = myFirebase.storage().ref('thumbnails');
      try {
        const fileRef = storageRef.child(fileName);
        fileRef
          .delete()
          .then(() => {
            setIsDeleted(true);
            // File deleted successfully
            console.log('file deleted successfully');
          })
          .catch((error) => {
            setIsError(error);
            setexerciseErrorMessage(error.message);
            console.log('error deleting file: ', error);
          });
      } catch (error) {
        return;
      }
    }
  };

  // const exerciseData = exercisesRef.get();

  // const [exerciseDocs, loading, error] = useCollectionData<Exercise>(exercisesRef, {
  //   idField: 'id',
  // });
  // console.log('exerciseDocs: ', exerciseDocs);
  // console.log(typeof exerciseDocs);

  // if (error) {
  //   setIsError(error);
  // }

  const clearError = () => {
    setIsError(undefined);
  };

  //   const convertExerciseDocs = () => {
  //     if (!exerciseDocs) {
  //       return {};
  //     }

  //     return Object.values(exerciseDocs).map((exercise) => {
  //       const exerciseId = exercise.id as unknown as number;

  //       return (exerciseDocs[exerciseId] = {
  //         ...exercise,
  //       });
  //     });
  //   };

  //   const exerciseData = {};

  // const convertExerciseDatabase = ():exerciseCollection => {
  //   if (!exerciseDatabase) {
  //     return {};
  //   }
  //  //add id to each exercise
  // }
  const exerciseData = useMemo(convertExerciseDatabase, [exerciseDatabase]);

  const hook: UseExercisesHook = {
    exerciseData: exerciseData as exerciseCollection,
    isExerciseError: isExerciseError,
    exerciseErrorMessage: exerciseErrorMessage,
    clearError: clearError,
    createExercise: createExercise,
    updateExercise: updateExercise,
    doDeleteMedia: doDeleteMedia,
    isDeleted: isDeleted,
    isCompleted: isCompleted,
    getTumbnailURL: getTumbnailURL,
    thumbnailURL: thumbnailURL,
    getVideoURL: getVideoURL,
    videoURL: videoURL,
    setStatusInactive: setStatusInactive,
    exerciseDatabaseLoading: exerciseDatabaseLoading,
    // loadingExercises: loadingExercises,
  };
  return useMemo(
    () => hook,
    [
      exerciseData,
      exerciseDatabaseLoading,
      isExerciseError,
      exerciseErrorMessage,
      doDeleteMedia,
      isDeleted,
      isCompleted,
      getTumbnailURL,
      thumbnailURL,
      getVideoURL,
      videoURL,
      // loadingExercises,
    ],
  );
};

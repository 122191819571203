/* ORYX App 2.0
 *
 * Created by ORYX Movement Solutions © 2022
 * ==================================================================
 *
 * Login form Component, including the logo.
 */

import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonNote,
  IonRow,
  IonText,
  IonToast,
} from '@ionic/react';
import { eyeOffOutline, eyeOutline, logoGoogle } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';

import loginImage from '../../../assets/login/login_image.png';
import ORYXlogo from '../../../assets/logo.png';
import ORYXlogo_white from '../../../assets/logo_white.png';

import { useAuthContext } from '../../../contexts/authContext';
import './index.css';

export const LoginForm = () => {
  const { height, width } = useWindowDimensions();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const largeScreen = width! >= 600 ? true : false;

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [showLoginError, setShowLoginError] = useState<boolean>(false);
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setShowPassword(!showPassword);
  };

  const { signInWithEmailAndPassword, state, clearError, signInWithGoogle } = useAuthContext();

  useEffect(() => {
    if (state.error) {
      console.log(state.error);
      setShowLoginError(true);
    }
  }, [state]);

  function login(e: React.FormEvent) {
    e.preventDefault();
    setFormSubmitted(true);
    if (!!email && !!password) {
      signInWithEmailAndPassword(email, password);
      console.log('login');
    }
  }

  const handleEmail = (e: any) => {
    setEmail(e.detail.value);
  };

  const handlePassword = (e: any) => {
    setPassword(e.detail.value);
  };

  // Validate email
  const [isTouched, setIsTouched] = useState(false);
  const [isValid, setIsValid] = useState<boolean>();
  const validateEmail = (email: string) => {
    return email.match(
      /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
    );
  };

  const validate = (ev: Event) => {
    const value = (ev.target as HTMLInputElement).value;

    setIsValid(undefined);

    if (value === '') return;

    validateEmail(value) !== null ? setIsValid(true) : setIsValid(false);
  };

  const markTouched = () => {
    setIsTouched(true);
  };

  return (
    <>
      <IonToast
        isOpen={showLoginError}
        message='Incorrect username / password. Please try again.'
        position='bottom'
        color='danger'
        onDidDismiss={() => {
          setShowLoginError(false);
          clearError();
        }}
        duration={2500}
      />
      <div className='login_Wrapper'>
        <form noValidate onSubmit={login}>
          <IonGrid className='login_Modal'>
            <IonRow>
              <IonImg src={loginImage} className='login_logo'></IonImg>
            </IonRow>
            <IonRow>
              {largeScreen ? (
                <IonCol size='12'>
                  <IonImg src={ORYXlogo} className='login_logo'></IonImg>
                </IonCol>
              ) : (
                <IonCol size='12'>
                  <IonImg src={ORYXlogo_white} className='login_logo'></IonImg>
                </IonCol>
              )}
            </IonRow>

            <IonRow>
              <IonCol size='12' className='ion-padding'>
                <p>Welcome back! Please sign in to your account.</p>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol size='12' className='ion-padding '>
                <IonList className='login_List'>
                  <IonItem
                    lines='full'
                    className={`login_Item ${isValid && 'ion-valid'} ${isValid === false && 'ion-invalid'} ${
                      isTouched && 'ion-touched'
                    }`}>
                    <IonLabel position='stacked'>Email</IonLabel>
                    <IonInput
                      name='email'
                      class='custom'
                      type='email'
                      required
                      clearInput={true}
                      placeholder='example@mail.com'
                      onIonChange={handleEmail}
                      onIonInput={(event) => validate(event)}
                      onIonBlur={() => markTouched()}></IonInput>
                    <IonNote slot='error'>Invalid email</IonNote>
                  </IonItem>

                  <IonItem
                    lines='full'
                    className={`login_Item ${isValid && 'ion-valid'} ${isValid === false && 'ion-invalid'} ${
                      isTouched && 'ion-touched'
                    }`}>
                    <IonLabel position='stacked'>Password</IonLabel>
                    {showPassword ? (
                      <IonIcon icon={eyeOffOutline} slot='end' onClick={togglePassword} id='eyeOff'></IonIcon>
                    ) : (
                      <IonIcon icon={eyeOutline} slot='end' onClick={togglePassword} id='eyeOn'></IonIcon>
                    )}

                    <IonInput
                      name='password'
                      required
                      type={showPassword ? 'text' : 'password'}
                      placeholder='Password'
                      onIonChange={handlePassword}></IonInput>
                  </IonItem>

                  {formSubmitted && (!email || !password) && (
                    <IonText color='danger'>Email and password are required.</IonText>
                  )}
                </IonList>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol size='6' className='ion-padding'></IonCol>
              <IonCol size='6' className='ion-align-self-end'>
                <IonButton
                  onClick={() => {
                    history.push(`/resetPassword`);
                  }}
                  className='secondary'>
                  Forgot password?
                </IonButton>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol size='6' className='ion-padding'>
                {largeScreen ? (
                  <IonButton expand='block' onClick={() => signInWithGoogle()} className='primary'>
                    <IonIcon src={logoGoogle} slot='icon-only' />
                  </IonButton>
                ) : (
                  <IonButton expand='block' onClick={() => signInWithGoogle()} className='primary'>
                    <IonIcon src={logoGoogle} slot='start' />
                  </IonButton>
                )}
              </IonCol>
              <IonCol size='6' className='ion-padding'>
                <IonButton type='submit' expand='block' className='primary'>
                  Sign in
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </form>
      </div>
    </>
  );
};

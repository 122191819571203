import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonNote,
  IonPage,
  IonRadio,
  IonRadioGroup,
  IonRow,
  IonTextarea,
  IonToast,
} from '@ionic/react';
import { useEffect, useState } from 'react';
import { ErrorToast } from '../../components/ErrorToast';
import { ORYXHeader } from '../../components/ui';
import { useAuthContext } from '../../contexts/authContext';
import { useExercises } from '../../hooks/useExercises';
import { useFirebaseUpload } from '../../hooks/useFirebaseUpload';
import { Exercise, exerciseCollection } from '../../model';
import { UploadArea } from './components';
import './index.css';

export const CreateExercisePage = () => {
  const { isError, errorMessage, clearError } = useFirebaseUpload();
  const [isTouched, setIsTouched] = useState(false);
  const [isValid, setIsValid] = useState<boolean>();
  const [exerciseName, setExerciseName] = useState<string>('');
  const [exerciseDescription, setExerciseDescription] = useState<string>('');
  const [thumbnail, setThumbnail] = useState('');
  const [video, setVideo] = useState('');
  const [exerciseEquipment, setExerciseEquipment] = useState<string[]>([]);
  const [coachingCues, setCoachingCues] = useState<string[]>([]);
  const [tags, setTags] = useState<string[]>([]);
  const [exerciseIntention, setExerciseIntention] = useState('');
  const [Level, setLevel] = useState('');
  const [exerciseComplexity, setExerciseComplexity] = useState('');
  const user = useAuthContext().state.userState?.user;
  const { createExercise, isCompleted, isExerciseError, exerciseErrorMessage, exerciseData } = useExercises(user?.uid);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [easyAlternative, setEasyAlternative] = useState<string>('');
  const [mediumAlternative, setMediumAlternative] = useState<string>('');
  const [hardAlternative, setHardAlternative] = useState<string>('');
  const [subtitle, setSubtitle] = useState<string>('');

  const handleName = (ev: Event) => {
    setExerciseName((ev.target as HTMLInputElement).value);
  };

  const handleThumbnail = (thumbNailUuid: string) => {
    setThumbnail(thumbNailUuid);
  };

  const handleDescription = (ev: Event) => {
    setExerciseDescription((ev.target as HTMLInputElement).value);
  };
  const handleSubtitle = (ev: Event) => {
    setSubtitle((ev.target as HTMLInputElement).value);
  };

  const handleEquipment = (ev: Event) => {
    const equipmentArray = (ev.target as HTMLInputElement).value.split(',');
    setExerciseEquipment(equipmentArray);
  };

  const handleCoachingCues = (ev: Event) => {
    const coachingCuesArray = (ev.target as HTMLInputElement).value.split(',');
    setCoachingCues(coachingCuesArray);
  };
  const handleExerciseIntention = (ev: Event) => {
    setExerciseIntention((ev.target as HTMLInputElement).value);
  };

  const handleLevel = (ev: Event) => {
    setLevel((ev.target as HTMLInputElement).value);
  };

  const handleComplexity = (ev: Event) => {
    setExerciseComplexity((ev.target as HTMLInputElement).value);
  };

  const handleTags = (ev: Event) => {
    const tagsArray = (ev.target as HTMLInputElement).value.split(',');
    setTags(tagsArray);
  };

  const handleEasyAlternative = (ev: Event) => {
    setEasyAlternative((ev.target as HTMLInputElement).value);
  };

  const handleMediumAlternative = (ev: Event) => {
    setMediumAlternative((ev.target as HTMLInputElement).value);
  };

  const handleHardAlternative = (ev: Event) => {
    setHardAlternative((ev.target as HTMLInputElement).value);
  };

  const markTouched = () => {
    setIsTouched(true);
  };

  function validAlternative(value: any) {
    const dbexercise = value[1];
    const dbexerciseName = dbexercise.Name;
    const dbexerciseLevel = dbexercise.Level;
    const dbexerciseComplexity = dbexercise.Complexity;

    return dbexerciseName === exerciseName && dbexerciseLevel === Level && dbexerciseComplexity !== exerciseComplexity;
  }

  const sortExerciseData = (exerciseData: exerciseCollection): Exercise[] => {
    // Create array of tuples (id, exercise)
    const items: [string, Exercise][] = Object.keys(exerciseData).map((id: string) => {
      return [id, exerciseData[id]];
    });
    //  filter out the exercises that have the same Name and Level but a different Complexity
    const filteredItems = items.filter(validAlternative);
    return filteredItems.map(([_id, exercise]) => exercise);
  };
  const [alternativeList, setAlternativeList] = useState<Exercise[]>([]);

  useEffect(() => {
    if (exerciseData && exerciseName && Level && exerciseComplexity) {
      setAlternativeList(sortExerciseData(exerciseData!));
    }
  }, [exerciseData, exerciseName, Level, exerciseComplexity]);

  const createExerciseObject = () => {
    const exercise = {
      Name: exerciseName,
      Subtitle: subtitle,
      Description: exerciseDescription,
      thumbnailURL: thumbnail,
      videoURL: video,
      Equipment: exerciseEquipment,
      CoachingCues: coachingCues,
      Type: tags,
      Intention: exerciseIntention,
      Level: Level,
      Complexity: exerciseComplexity,
      EasyAlternative: easyAlternative,
      MediumAlternative: mediumAlternative,
      HardAlternative: hardAlternative,
      status: 'active',
    };
    return exercise;
  };

  // The actual creation once you click Submit in the form
  const create = async (e: React.FormEvent) => {
    e.preventDefault();
    setFormSubmitted(true);

    if (
      !!user &&
      !!exerciseName &&
      !!exerciseDescription &&
      !!thumbnail &&
      !!video &&
      !!exerciseEquipment &&
      !!coachingCues &&
      !!tags &&
      !!exerciseIntention &&
      !!Level &&
      !!exerciseComplexity
    ) {
      const exercise = createExerciseObject();
      // Perform the acutal file upload using the hook
      await createExercise(exercise).then(() => {
        setFormSubmitted(false);
        history.back();
      });
    }
  };

  return (
    <IonPage id='main'>
      <IonToast
        isOpen={isCompleted ? true : false}
        message={'Exercise created successfully'}
        position='bottom'
        color='success'
        duration={2500}
      />
      <IonToast
        isOpen={isExerciseError ? true : false}
        message={exerciseErrorMessage}
        position='bottom'
        color='error'
        duration={2500}
      />
      <ORYXHeader backTarget='/exercises' />
      <IonContent fullscreen className='appBackground'>
        <IonGrid fixed>
          <IonRow className='ion-justify-content-center'>
            <IonCard className='createExercise_Card'>
              <IonCardHeader>
                <IonCardTitle>Create an exercise</IonCardTitle>
                <IonCardSubtitle>Use the form below to create an exercise</IonCardSubtitle>
              </IonCardHeader>

              <IonCardContent className=''>
                <div className='scrollable'>
                  <form noValidate onSubmit={create}>
                    <IonGrid fixed>
                      <IonList lines='none' mode='ios'>
                        <IonItem
                          className={`exercise_Create_ListItem ${isValid && 'ion-valid'} ${
                            isValid === false && 'ion-invalid'
                          } ${isTouched && 'ion-touched'}`}>
                          <IonLabel className='exerciseLabel'>Exercise Name: </IonLabel>
                          <IonInput
                            name='Name'
                            type='text'
                            required
                            value={exerciseName}
                            clearInput={true}
                            placeholder='Exercise name'
                            onIonChange={handleName}
                            onIonBlur={() => markTouched()}></IonInput>
                        </IonItem>
                      </IonList>
                      {!exerciseName && (
                        <IonRow className='disabled'>
                          <IonCol size='6'>
                            <UploadArea
                              type='image'
                              exerciseName={exerciseName}
                              disabled={true}
                              setThumbnail={setThumbnail}
                              setVideo={setVideo}
                            />
                          </IonCol>
                          <IonCol size='6'>
                            <UploadArea
                              type='video'
                              exerciseName={exerciseName}
                              disabled={true}
                              setThumbnail={setThumbnail}
                              setVideo={setVideo}
                            />
                          </IonCol>
                        </IonRow>
                      )}
                      {exerciseName && (
                        <>
                          <IonRow>
                            <IonCol size='6'>
                              <UploadArea
                                type='image'
                                exerciseName={exerciseName}
                                disabled={false}
                                setThumbnail={setThumbnail}
                                setVideo={setVideo}
                              />
                            </IonCol>
                            <IonCol size='6'>
                              <UploadArea
                                type='video'
                                exerciseName={exerciseName}
                                disabled={false}
                                setThumbnail={setThumbnail}
                                setVideo={setVideo}
                              />
                            </IonCol>
                          </IonRow>
                          <IonRow>
                            <IonCol size='6'>
                              <p>{thumbnail}</p>
                            </IonCol>
                            <IonCol size='6'>
                              <p>{video}</p>
                            </IonCol>
                          </IonRow>
                        </>
                      )}

                      <IonList mode='ios' lines='none'>
                        <IonItem
                          className={`exercise_Create_ListItem ${isValid && 'ion-valid'} ${
                            isValid === false && 'ion-invalid'
                          } ${isTouched && 'ion-touched'}`}>
                          <IonLabel className='exerciseLabel'>Exercise Subtitle: </IonLabel>
                          <IonTextarea
                            name='Subtitle'
                            required
                            autoGrow={true}
                            placeholder='Exercise Subtitle'
                            wrap='soft'
                            onIonChange={handleSubtitle}
                            onIonBlur={() => markTouched()}
                            onIonFocus={() => markTouched()}></IonTextarea>
                        </IonItem>
                        <IonItem
                          className={`exercise_Create_ListItem ${isValid && 'ion-valid'} ${
                            isValid === false && 'ion-invalid'
                          } ${isTouched && 'ion-touched'}`}>
                          <IonLabel className='exerciseLabel'>Exercise Description: </IonLabel>
                          <IonTextarea
                            name='Description'
                            required
                            autoGrow={true}
                            placeholder='Exercise Description'
                            wrap='soft'
                            onIonChange={handleDescription}
                            onIonBlur={() => markTouched()}
                            onIonFocus={() => markTouched()}></IonTextarea>
                        </IonItem>

                        <IonItem
                          className={`exercise_Create_ListItem ${isValid && 'ion-valid'} ${
                            isValid === false && 'ion-invalid'
                          } ${isTouched && 'ion-touched'}`}>
                          <IonLabel className='exerciseLabel'>Exercise Equipment: </IonLabel>
                          <IonInput
                            name='Equipment'
                            required
                            placeholder='Exercise Equipment'
                            type='text'
                            clearInput={true}
                            onIonChange={handleEquipment}
                            onIonBlur={() => markTouched()}
                            onIonFocus={() => markTouched()}></IonInput>
                          <IonNote mode='md' slot='helper'>
                            <small>Separate multiple equipments by a comma.</small>
                          </IonNote>
                        </IonItem>
                        <IonItem
                          className={`exercise_Create_ListItem ${isValid && 'ion-valid'} ${
                            isValid === false && 'ion-invalid'
                          } ${isTouched && 'ion-touched'}`}>
                          <IonLabel className='exerciseLabel'>Exercise Tags: </IonLabel>
                          <IonInput
                            name='Type'
                            required
                            placeholder='Exercise Tags'
                            type='text'
                            clearInput={true}
                            onIonChange={handleTags}
                            onIonBlur={() => markTouched()}
                            onIonFocus={() => markTouched()}></IonInput>
                          <IonNote mode='md' slot='helper'>
                            <small>Separate multiple tags by a comma.</small>
                          </IonNote>
                        </IonItem>
                        <IonItem
                          className={`exercise_Create_ListItem ${isValid && 'ion-valid'} ${
                            isValid === false && 'ion-invalid'
                          } ${isTouched && 'ion-touched'}`}>
                          <IonLabel className='exerciseLabel'>Coaching cues: </IonLabel>
                          <IonInput
                            name='CoachingCues'
                            required
                            placeholder='Coaching cues'
                            type='text'
                            clearInput={true}
                            onIonChange={handleCoachingCues}
                            onIonBlur={() => markTouched()}
                            onIonFocus={() => markTouched()}></IonInput>
                          <IonNote mode='md' slot='helper'>
                            <small>Separate multiple cues by a comma.</small>
                          </IonNote>
                        </IonItem>
                        <IonItem
                          className={`exercise_Create_ListItem ${isValid && 'ion-valid'} ${
                            isValid === false && 'ion-invalid'
                          } ${isTouched && 'ion-touched'}`}>
                          <IonLabel className='exerciseLabel'>Exercise Intention:</IonLabel>
                          <IonTextarea
                            name='Intention'
                            required
                            autoGrow={true}
                            placeholder='Exercise Intentions'
                            wrap='soft'
                            onIonChange={handleExerciseIntention}
                            onIonBlur={() => markTouched()}
                            onIonFocus={() => markTouched()}></IonTextarea>
                        </IonItem>
                        <IonItem
                          className={`exercise_Create_ListItem ${isValid && 'ion-valid'} ${
                            isValid === false && 'ion-invalid'
                          } ${isTouched && 'ion-touched'}`}>
                          <IonLabel className='exerciseLabel'>Exercise Level:</IonLabel>
                          <IonRadioGroup onIonChange={handleLevel}>
                            <IonItem>
                              <IonLabel>Beginner</IonLabel>
                              <IonRadio slot='end' value='Beginner'></IonRadio>
                            </IonItem>
                            <IonItem>
                              <IonLabel>Advanced</IonLabel>
                              <IonRadio slot='end' value='Advanced'></IonRadio>
                            </IonItem>
                            <IonItem>
                              <IonLabel>Expert</IonLabel>
                              <IonRadio slot='end' value='Expert'></IonRadio>
                            </IonItem>
                          </IonRadioGroup>
                        </IonItem>
                        <IonItem
                          className={`exercise_Create_ListItem ${isValid && 'ion-valid'} ${
                            isValid === false && 'ion-invalid'
                          } ${isTouched && 'ion-touched'}`}>
                          <IonLabel className='exerciseLabel'>Exercise Complexity:</IonLabel>
                          <IonRadioGroup onIonChange={handleComplexity}>
                            <IonItem>
                              <IonLabel>Easy</IonLabel>
                              <IonRadio slot='end' value='Easy'></IonRadio>
                            </IonItem>
                            <IonItem>
                              <IonLabel>Medium</IonLabel>
                              <IonRadio slot='end' value='Medium'></IonRadio>
                            </IonItem>
                            <IonItem>
                              <IonLabel>Hard</IonLabel>
                              <IonRadio slot='end' value='Hard'></IonRadio>
                            </IonItem>
                          </IonRadioGroup>
                        </IonItem>
                        {exerciseComplexity !== 'Easy' && exerciseComplexity !== '' && (
                          <IonItem
                            className={`exercise_Create_ListItem ${isValid && 'ion-valid'} ${
                              isValid === false && 'ion-invalid'
                            } ${isTouched && 'ion-touched'}`}>
                            <IonLabel className='exerciseLabel'>Easy Alternative:</IonLabel>
                            <IonRadioGroup onIonChange={handleEasyAlternative} allowEmptySelection={true}>
                              {alternativeList.map((alternative, keyEasy) => {
                                return (
                                  <>
                                    <IonItem key={keyEasy}>
                                      <IonLabel>
                                        {alternative.Name} {' - '} {alternative.Complexity}
                                      </IonLabel>
                                      <IonRadio slot='end' value={alternative.id}></IonRadio>
                                    </IonItem>
                                  </>
                                );
                              })}
                            </IonRadioGroup>
                          </IonItem>
                        )}
                        {exerciseComplexity !== 'Medium' && exerciseComplexity !== '' && (
                          <IonItem
                            className={`exercise_Create_ListItem ${isValid && 'ion-valid'} ${
                              isValid === false && 'ion-invalid'
                            } ${isTouched && 'ion-touched'}`}>
                            <IonLabel className='exerciseLabel'>Medium Alternative: </IonLabel>
                            <IonRadioGroup onIonChange={handleMediumAlternative} allowEmptySelection={true}>
                              {alternativeList.map((alternative, keyMedium) => {
                                return (
                                  <>
                                    <IonItem key={keyMedium}>
                                      <IonLabel>
                                        {alternative.Name} {' - '} {alternative.Complexity}
                                      </IonLabel>
                                      <IonRadio slot='end' value={alternative.id}></IonRadio>
                                    </IonItem>
                                  </>
                                );
                              })}
                            </IonRadioGroup>
                          </IonItem>
                        )}
                        {exerciseComplexity !== 'Hard' && exerciseComplexity !== '' && (
                          <IonItem
                            className={`exercise_Create_ListItem ${isValid && 'ion-valid'} ${
                              isValid === false && 'ion-invalid'
                            } ${isTouched && 'ion-touched'}`}>
                            <IonLabel className='exerciseLabel'>Hard Alternative: </IonLabel>
                            <IonRadioGroup onIonChange={handleHardAlternative} allowEmptySelection={true}>
                              {alternativeList.map((alternative, key) => {
                                return (
                                  <>
                                    <IonItem key={key}>
                                      <IonLabel>
                                        {alternative.Name} {' - '} {alternative.Complexity}
                                      </IonLabel>
                                      <IonRadio slot='end' value={alternative.id}></IonRadio>
                                    </IonItem>
                                  </>
                                );
                              })}
                            </IonRadioGroup>
                          </IonItem>
                        )}
                      </IonList>
                      <IonRow>
                        <IonCol size='6'></IonCol>
                        <IonCol size='6' className='ion-text-center'>
                          <IonButton className='primary' type='submit'>
                            Create Exercise
                          </IonButton>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </form>
                </div>
              </IonCardContent>
            </IonCard>
          </IonRow>
          <IonRow></IonRow>
        </IonGrid>
      </IonContent>
      <ErrorToast message={isError ? errorMessage : ''} clearError={() => clearError()} />

      <ErrorToast message={isExerciseError ? exerciseErrorMessage : ''} clearError={() => clearError()} />
    </IonPage>
  );
};

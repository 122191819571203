import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonChip,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonNote,
  IonPage,
  IonRadio,
  IonRadioGroup,
  IonRow,
  IonTextarea,
  IonToast,
} from '@ionic/react';
import { pencilOutline } from 'ionicons/icons';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { ORYXHeader } from '../../components/ui';
import { useAuthContext } from '../../contexts/authContext';
import { useExercises } from '../../hooks/useExercises';
import { Exercise, exerciseCollection } from '../../model';
import { CreateAlternativeModal, UploadArea } from './components';
import './index.css';

export interface IExerciseDetailPageProps {
  exercise?: Exercise;
}
interface ExerciseParams {
  id: string;
}

export const ExerciseDetailPage = () => {
  const { id } = useParams<ExerciseParams>();
  const user = useAuthContext().state.userState?.user;
  const {
    getTumbnailURL,
    thumbnailURL,
    exerciseData,
    getVideoURL,
    videoURL,
    updateExercise,
    isCompleted,
    isExerciseError,
    exerciseErrorMessage,
  } = useExercises(user?.uid);
  // const [exercise, setExercise] = useState<Exercise>();
  const [thumbnail, setThumbnail] = useState<string>('');
  const [video, setVideo] = useState<string>('');
  const [isTouched, setIsTouched] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>();
  const [exerciseEquipment, setExerciseEquipment] = useState<string[]>();
  const [coachingCues, setCoachingCues] = useState<string[]>();
  const [tags, setTags] = useState<string[]>();
  const [exerciseIntention, setExerciseIntention] = useState<string>();
  const [intentionEdit, setIntentionEdit] = useState<boolean>(false);
  const [Level, setLevel] = useState<string>();
  const [exerciseComplexity, setExerciseComplexity] = useState<string>();
  const [exerciseDescription, setExerciseDescription] = useState<string>();
  const [descriptionEdit, setDescriptionEdit] = useState<boolean>(false);
  const [equipmentEdit, setEquipmentEdit] = useState<boolean>(false);
  const [coachingCuesEdit, setCoachingCuesEdit] = useState<boolean>(false);
  const [tagsEdit, setTagsEdit] = useState<boolean>(false);
  const [easyAlternative, setEasyAlternative] = useState<string>('');
  const [mediumAlternative, setMediumAlternative] = useState<string>('');
  const [hardAlternative, setHardAlternative] = useState<string>('');
  // const [alternativeList, setAlternativeList] = useState<Exercise[]>([]);
  const [alternativeComplexity, setAlternativeComplexity] = useState<string>('');
  const [createAlternativeModal, setCreateAlternativeModal] = useState<boolean>(false);
  const [complex, setComplex] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isError, setIsError] = useState(false);

  const [subtitle, setSubtitle] = useState<string>('');
  const [subtitleEdit, setSubtitleEdit] = useState<boolean>(false);
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);

  const getThumbnailURL = async () => {
    await getTumbnailURL(exercise!.thumbnailURL).then((url) => {
      return url;
    });
  };

  const getvideoURL = async () => {
    await getVideoURL(exercise!.videoURL).then((url) => {
      return url;
    });
  };
  const handleDescription = (ev: Event) => {
    setExerciseDescription((ev.target as HTMLInputElement).value);
    setUnsavedChanges(true);
  };

  const handleSubtitle = (ev: Event) => {
    setSubtitle((ev.target as HTMLInputElement).value);
    setUnsavedChanges(true);
  };

  const handleEquipment = (ev: Event) => {
    const equipmentArray = (ev.target as HTMLInputElement).value.split(',');
    setExerciseEquipment(equipmentArray);
    setUnsavedChanges(true);
  };

  const handleCoachingCues = (ev: Event) => {
    const coachingCuesArray = (ev.target as HTMLInputElement).value.split(',');
    setCoachingCues(coachingCuesArray);
    setUnsavedChanges(true);
  };
  const handleExerciseIntention = (ev: Event) => {
    setExerciseIntention((ev.target as HTMLInputElement).value);
    setUnsavedChanges(true);
  };

  const handleLevel = (ev: Event) => {
    setLevel((ev.target as HTMLInputElement).value);
    setUnsavedChanges(true);
  };

  const handleComplexity = (ev: Event) => {
    setExerciseComplexity((ev.target as HTMLInputElement).value);
    setComplex((ev.target as HTMLInputElement).value);
    setUnsavedChanges(true);
  };

  const handleTags = (ev: Event) => {
    const tagsArray = (ev.target as HTMLInputElement).value.split(',');
    setTags(tagsArray);
    setUnsavedChanges(true);
  };
  const handleEasyAlternative = (ev: Event) => {
    setEasyAlternative((ev.target as HTMLInputElement).value);
    setUnsavedChanges(true);
  };

  const handleMediumAlternative = (ev: Event) => {
    setMediumAlternative((ev.target as HTMLInputElement).value);
    setUnsavedChanges(true);
  };

  const handleHardAlternative = (ev: Event) => {
    setHardAlternative((ev.target as HTMLInputElement).value);
    setUnsavedChanges(true);
  };

  const markTouched = () => {
    setIsTouched(true);
  };

  function validAlternative(value: any) {
    const dbexercise = value[1];
    const dbexerciseName = dbexercise.Name;
    const dbexerciseLevel = dbexercise.Level;
    const dbexerciseComplexity = dbexercise.Complexity;
    const dbexerciseId = dbexercise.id;

    return (
      dbexerciseName === exercise?.Name &&
      dbexerciseLevel === exercise?.Level &&
      dbexerciseComplexity !== exercise?.Complexity &&
      dbexerciseId !== exercise?.id
    );
  }

  const sortExerciseData = (exerciseData: exerciseCollection): Exercise[] => {
    // Create array of tuples (id, exercise)
    const items: [string, Exercise][] = Object.keys(exerciseData).map((id: string) => {
      return [id, exerciseData[id]];
    });
    //  filter out the exercises that have the same Name and Level but a different Complexity
    const filteredItems = items.filter(validAlternative);
    return filteredItems.map(([_id, exercise]) => exercise);
  };

  const setExercise = () => {
    if (exerciseData) {
      const exercise = exerciseData[id];
      return exercise;
    }
  };

  const exercise = useMemo(() => setExercise(), [exerciseData]);
  const complexityHelper = useMemo(() => setComplex(exercise ? exercise.Complexity : ''), [exercise]);
  const alternativeList = useMemo(() => sortExerciseData(exerciseData!), [exerciseData]);

  const easyAlternatives = useMemo(
    () => alternativeList.filter((exercise) => exercise.Complexity === 'Easy'),
    [alternativeList],
  );
  const mediumAlternatives = useMemo(
    () => alternativeList.filter((exercise) => exercise.Complexity === 'Medium'),
    [alternativeList],
  );
  const hardAlternatives = useMemo(
    () => alternativeList.filter((exercise) => exercise.Complexity === 'Hard'),
    [alternativeList],
  );

  useEffect(() => {
    if (isExerciseError) setIsError(true);
  }, [isExerciseError]);

  useEffect(() => {
    exercise && getThumbnailURL();
    exercise && getvideoURL();
  }, [exercise]);

  const updateExerciseObject = () => {
    const updatedExercise = {
      Name: exercise?.Name,
      Subtitle: subtitle ? subtitle : exercise?.Subtitle,
      Description: exerciseDescription ? exerciseDescription : exercise?.Description,
      thumbnailURL: thumbnail ? thumbnail : exercise?.thumbnailURL,
      videoURL: video ? video : exercise?.videoURL,
      Equipment: exerciseEquipment ? exerciseEquipment : exercise?.Equipment,
      CoachingCues: coachingCues ? coachingCues : exercise?.CoachingCues,
      Type: tags ? tags : exercise?.Type,
      Intention: exerciseIntention ? exerciseIntention : exercise?.Intention,
      Level: Level ? Level : exercise?.Level,
      Complexity: exerciseComplexity ? exerciseComplexity : exercise?.Complexity,
      EasyAlternative: easyAlternative ? easyAlternative : exercise?.EasyAlternative,
      MediumAlternative: mediumAlternative ? mediumAlternative : exercise?.MediumAlternative,
      HardAlternative: hardAlternative ? hardAlternative : exercise?.HardAlternative,
      status: 'active',
    };
    return updatedExercise;
  };

  const update = async (e: React.FormEvent) => {
    e.preventDefault();
    setFormSubmitted(true);

    const updatedExercise = updateExerciseObject();

    if (updatedExercise.Subtitle === undefined || updatedExercise.Subtitle === null) {
      updatedExercise.Subtitle = '';
    }

    // Perform the acutal file upload using the hook
    await updateExercise(updatedExercise, id).then(() =>
      setTimeout(() => {
        history.back();
        setUnsavedChanges(false);
      }, 2500),
    );
  };

  return (
    <>
      {exercise && (
        <IonModal
          isOpen={createAlternativeModal}
          onDidDismiss={() => setCreateAlternativeModal(false)}
          className='modal_Large'>
          <CreateAlternativeModal
            exerciseName={exercise.Name}
            alternativeComplexity={alternativeComplexity}
            setCreateAlternativeModal={setCreateAlternativeModal}
            exercise={exercise}
          />
          {/* <IonButton onClick={() => setShowModal(false)}>Close</IonButton> */}
        </IonModal>
      )}

      <IonPage id='main'>
        <ORYXHeader backTarget='/exercises' unsavedChanges={unsavedChanges} />
        <IonContent fullscreen className='appBackground'>
          <IonGrid fixed>
            <IonRow className='ion-justify-content-center'>
              {exercise && (
                <IonCard className='exerciseDetail_Card'>
                  <IonCardHeader>
                    <IonCardTitle>{exercise.Name}</IonCardTitle>
                    <IonCardSubtitle>{id}</IonCardSubtitle>
                  </IonCardHeader>

                  <IonCardContent className=' '>
                    <div className='scrollable'>
                      <form noValidate onSubmit={update}>
                        <IonGrid fixed>
                          <IonRow>
                            <IonCol size='6'>
                              <UploadArea
                                type='image'
                                exerciseName={exercise.Name}
                                disabled={false}
                                setThumbnail={setThumbnail}
                                setVideo={setVideo}
                                thumbnail={thumbnailURL}
                              />
                            </IonCol>
                            <IonCol size='6'>
                              <UploadArea
                                type='video'
                                exerciseName={exercise.Name}
                                disabled={false}
                                setThumbnail={setThumbnail}
                                setVideo={setVideo}
                                video={videoURL}
                              />
                            </IonCol>
                          </IonRow>
                          <IonList mode='md' lines='none'>
                            <IonItem
                              className={`exercise_Create_ListItem ${isValid && 'ion-valid'} ${
                                isValid === false && 'ion-invalid'
                              } ${isTouched && 'ion-touched'}`}>
                              <IonLabel className='exerciseLabel'>Exercise Subtitle: </IonLabel>
                              {!subtitleEdit && (
                                <>
                                  <IonButton
                                    icon-only
                                    slot='end'
                                    fill='clear'
                                    onClick={() => {
                                      setSubtitleEdit(true);
                                    }}>
                                    <IonIcon src={pencilOutline} slot='end' />
                                  </IonButton>
                                  <IonTextarea
                                    name='Subtitle'
                                    readonly
                                    autoGrow={true}
                                    wrap='soft'
                                    className='ion-text-end'>
                                    {exercise.Subtitle}
                                  </IonTextarea>
                                </>
                              )}
                              {subtitleEdit && (
                                <>
                                  <IonTextarea
                                    name='Subtitle'
                                    required
                                    autoGrow={true}
                                    wrap='soft'
                                    onIonChange={handleSubtitle}
                                    onIonBlur={() => markTouched()}
                                    onIonFocus={() => markTouched()}></IonTextarea>
                                  <IonNote mode='ios' slot='helper'>
                                    <small>{exercise.Subtitle}</small>
                                  </IonNote>
                                </>
                              )}
                            </IonItem>

                            <IonItem
                              className={`exercise_Create_ListItem ${isValid && 'ion-valid'} ${
                                isValid === false && 'ion-invalid'
                              } ${isTouched && 'ion-touched'}`}>
                              <IonLabel className='exerciseLabel'>Exercise Description: </IonLabel>
                              {!descriptionEdit && (
                                <>
                                  <IonButton
                                    icon-only
                                    slot='end'
                                    fill='clear'
                                    onClick={() => {
                                      setDescriptionEdit(true);
                                    }}>
                                    <IonIcon src={pencilOutline} slot='end' />
                                  </IonButton>
                                  <IonTextarea
                                    name='Description'
                                    readonly
                                    autoGrow={true}
                                    wrap='soft'
                                    className='ion-text-end'>
                                    {exercise.Description}
                                  </IonTextarea>
                                </>
                              )}
                              {descriptionEdit && (
                                <>
                                  <IonTextarea
                                    name='Description'
                                    required
                                    autoGrow={true}
                                    wrap='soft'
                                    onIonChange={handleDescription}
                                    onIonBlur={() => markTouched()}
                                    onIonFocus={() => markTouched()}></IonTextarea>
                                  <IonNote mode='ios' slot='helper'>
                                    <small>{exercise.Description}</small>
                                  </IonNote>
                                </>
                              )}
                            </IonItem>

                            <IonItem
                              className={`exercise_Create_ListItem ${isValid && 'ion-valid'} ${
                                isValid === false && 'ion-invalid'
                              } ${isTouched && 'ion-touched'}`}>
                              <IonLabel className='exerciseLabel'>Exercise Equipment:</IonLabel>
                              {!equipmentEdit && (
                                <>
                                  <IonButton icon-only slot='end' fill='clear' onClick={() => setEquipmentEdit(true)}>
                                    <IonIcon src={pencilOutline} slot='end' />
                                  </IonButton>
                                  <IonTextarea autoGrow={true} wrap='soft' className='ion-text-end'>
                                    {exercise.Equipment.map((equipment) => (
                                      <>
                                        <IonChip key={equipment}> {equipment} </IonChip>
                                      </>
                                    ))}
                                  </IonTextarea>
                                </>
                              )}
                              {equipmentEdit && (
                                <>
                                  <IonInput
                                    name='Equipment'
                                    required
                                    type='text'
                                    clearInput={true}
                                    onIonChange={handleEquipment}
                                    onIonBlur={() => markTouched()}
                                    onIonFocus={() => markTouched()}></IonInput>
                                  <IonNote mode='md' slot='helper'>
                                    <small>
                                      {exercise.Equipment.join(', ')} &nbsp;&nbsp; - &nbsp;&nbsp;{' '}
                                      <strong>Separate multiple equipments by a comma.</strong>
                                    </small>
                                  </IonNote>
                                </>
                              )}
                            </IonItem>
                            <IonItem
                              className={`exercise_Create_ListItem ${isValid && 'ion-valid'} ${
                                isValid === false && 'ion-invalid'
                              } ${isTouched && 'ion-touched'}`}>
                              <IonLabel className='exerciseLabel'>Exercise Tags:</IonLabel>
                              {!tagsEdit && (
                                <>
                                  <IonButton
                                    icon-only
                                    slot='end'
                                    fill='clear'
                                    onClick={() => {
                                      setTagsEdit(true);
                                    }}>
                                    <IonIcon src={pencilOutline} slot='end' />
                                  </IonButton>
                                  <IonTextarea autoGrow={true} wrap='soft' className='ion-text-end'>
                                    {exercise.Type.map((type) => (
                                      <>
                                        <IonChip key={type}> {type} </IonChip>
                                      </>
                                    ))}
                                  </IonTextarea>
                                </>
                              )}
                              {tagsEdit && (
                                <>
                                  <IonInput
                                    name='Type'
                                    required
                                    type='text'
                                    clearInput={true}
                                    onIonChange={handleTags}
                                    onIonBlur={() => markTouched()}
                                    onIonFocus={() => markTouched()}></IonInput>
                                  <IonNote mode='md' slot='helper'>
                                    <small>
                                      {exercise.Type.join(', ')} &nbsp;&nbsp; - &nbsp;&nbsp;{' '}
                                      <strong>Separate multiple tags by a comma.</strong>
                                    </small>
                                  </IonNote>
                                </>
                              )}
                            </IonItem>
                            <IonItem
                              className={`exercise_Create_ListItem ${isValid && 'ion-valid'} ${
                                isValid === false && 'ion-invalid'
                              } ${isTouched && 'ion-touched'}`}>
                              <IonLabel className='exerciseLabel'>Coaching cues: </IonLabel>
                              {!coachingCuesEdit && (
                                <>
                                  <IonButton
                                    icon-only
                                    slot='end'
                                    fill='clear'
                                    onClick={() => setCoachingCuesEdit(true)}>
                                    <IonIcon src={pencilOutline} slot='end' />
                                  </IonButton>
                                  <IonTextarea autoGrow={true} wrap='soft' className='ion-text-end'>
                                    {exercise.CoachingCues.map((cue) => (
                                      <>
                                        <IonChip key={cue}> {cue} </IonChip>
                                      </>
                                    ))}
                                  </IonTextarea>
                                </>
                              )}
                              {coachingCuesEdit && (
                                <>
                                  <IonInput
                                    name='CoachingCues'
                                    required
                                    type='text'
                                    clearInput={true}
                                    onIonChange={handleCoachingCues}
                                    onIonBlur={() => markTouched()}
                                    onIonFocus={() => markTouched()}></IonInput>
                                  <IonNote mode='md' slot='helper'>
                                    <small>
                                      {exercise.CoachingCues.join(', ')} &nbsp;&nbsp; - &nbsp;&nbsp;{' '}
                                      <strong>Separate multiple cues by a comma.</strong>
                                    </small>
                                  </IonNote>
                                </>
                              )}
                            </IonItem>
                            <IonItem
                              className={`exercise_Create_ListItem ${isValid && 'ion-valid'} ${
                                isValid === false && 'ion-invalid'
                              } ${isTouched && 'ion-touched'}`}>
                              <IonLabel className='exerciseLabel'>Exercise Intention: </IonLabel>
                              {!intentionEdit && (
                                <>
                                  <IonButton
                                    icon-only
                                    slot='end'
                                    fill='clear'
                                    onClick={() => {
                                      setIntentionEdit(true);
                                    }}>
                                    <IonIcon src={pencilOutline} slot='end' />
                                  </IonButton>
                                  <IonTextarea
                                    name='Description'
                                    readonly
                                    autoGrow={true}
                                    wrap='soft'
                                    className='ion-text-end'>
                                    {exercise.Intention}
                                  </IonTextarea>
                                </>
                              )}
                              {intentionEdit && (
                                <>
                                  <IonTextarea
                                    name='Intention'
                                    required
                                    autoGrow={true}
                                    wrap='soft'
                                    onIonChange={handleExerciseIntention}
                                    onIonBlur={() => markTouched()}
                                    onIonFocus={() => markTouched()}>
                                    {exercise.Intention ? exercise.Intention : 'Add exercise intention'}
                                  </IonTextarea>
                                  <IonNote mode='ios' slot='helper'>
                                    <small>{exercise.Intention}</small>
                                  </IonNote>
                                </>
                              )}
                            </IonItem>
                            <IonItem
                              className={`exercise_Create_ListItem ${isValid && 'ion-valid'} ${
                                isValid === false && 'ion-invalid'
                              } ${isTouched && 'ion-touched'}`}>
                              <IonLabel className='exerciseLabel'>Exercise Level:</IonLabel>
                              <IonRadioGroup onIonChange={handleLevel} value={Level ? Level : exercise.Level}>
                                <IonItem>
                                  <IonLabel>Beginner</IonLabel>
                                  <IonRadio slot='end' value='Beginner'></IonRadio>
                                </IonItem>
                                <IonItem>
                                  <IonLabel>Intermediate</IonLabel>
                                  <IonRadio slot='end' value='Intermediate'></IonRadio>
                                </IonItem>
                                <IonItem>
                                  <IonLabel>Advanced</IonLabel>
                                  <IonRadio slot='end' value='Advanced'></IonRadio>
                                </IonItem>
                              </IonRadioGroup>
                            </IonItem>
                            <IonItem
                              className={`exercise_Create_ListItem ${isValid && 'ion-valid'} ${
                                isValid === false && 'ion-invalid'
                              } ${isTouched && 'ion-touched'}`}>
                              <IonLabel className='exerciseLabel'>Exercise Complexity: </IonLabel>
                              <IonRadioGroup
                                onIonChange={handleComplexity}
                                value={exerciseComplexity ? exerciseComplexity : exercise.Complexity}>
                                <IonItem>
                                  <IonLabel>Easy</IonLabel>
                                  <IonRadio slot='end' value='Easy'></IonRadio>
                                </IonItem>
                                <IonItem>
                                  <IonLabel>Medium</IonLabel>
                                  <IonRadio slot='end' value='Medium'></IonRadio>
                                </IonItem>
                                <IonItem>
                                  <IonLabel>Hard</IonLabel>
                                  <IonRadio slot='end' value='Hard'></IonRadio>
                                </IonItem>
                              </IonRadioGroup>
                            </IonItem>
                            {exerciseComplexity !== 'Easy' && exerciseComplexity !== '' && complex !== 'Easy' && (
                              <IonItem
                                className={`exercise_Create_ListItem ${isValid && 'ion-valid'} ${
                                  isValid === false && 'ion-invalid'
                                } ${isTouched && 'ion-touched'}`}>
                                <IonLabel className='exerciseLabel'>Easy Alternative:</IonLabel>
                                <IonRadioGroup
                                  onIonChange={handleEasyAlternative}
                                  allowEmptySelection={true}
                                  value={easyAlternative ? easyAlternative : exercise.EasyAlternative}>
                                  {easyAlternatives.length === 0 && (
                                    <IonItem>
                                      <IonLabel>No alternatives available</IonLabel>
                                      <IonButton
                                        slot='end'
                                        onClick={() => {
                                          console.log('click');
                                          setAlternativeComplexity('Easy');
                                          setCreateAlternativeModal(true);
                                        }}
                                        className='primary'>
                                        Create Easy Alternative
                                      </IonButton>
                                    </IonItem>
                                  )}
                                  {easyAlternatives.map((alternative, keyEasy) => {
                                    return (
                                      <>
                                        <IonItem key={keyEasy}>
                                          <IonLabel>
                                            {alternative.Name} {' - '} {alternative.Complexity}
                                          </IonLabel>
                                          <IonRadio slot='end' value={alternative.id}></IonRadio>
                                        </IonItem>
                                      </>
                                    );
                                  })}
                                </IonRadioGroup>
                              </IonItem>
                            )}
                            {exerciseComplexity !== 'Medium' && exerciseComplexity !== '' && complex !== 'Medium' && (
                              <IonItem
                                className={`exercise_Create_ListItem ${isValid && 'ion-valid'} ${
                                  isValid === false && 'ion-invalid'
                                } ${isTouched && 'ion-touched'}`}>
                                <IonLabel className='exerciseLabel'>Medium Alternative: </IonLabel>
                                <IonRadioGroup
                                  onIonChange={handleMediumAlternative}
                                  allowEmptySelection={true}
                                  value={mediumAlternative ? mediumAlternative : exercise.MediumAlternative}>
                                  {mediumAlternatives.length === 0 && (
                                    <IonItem>
                                      <IonLabel>No alternatives available</IonLabel>
                                      <IonButton
                                        onClick={() => {
                                          console.log('click');
                                          setAlternativeComplexity('Medium');
                                          setCreateAlternativeModal(true);
                                        }}
                                        className='primary'>
                                        Create Medium Alternative
                                      </IonButton>
                                    </IonItem>
                                  )}
                                  {mediumAlternatives.map((alternative, keyMedium) => {
                                    return (
                                      <>
                                        <IonItem key={keyMedium}>
                                          <IonLabel>
                                            {alternative.Name} {' - '} {alternative.Complexity}
                                          </IonLabel>
                                          <IonRadio slot='end' value={alternative.id}></IonRadio>
                                        </IonItem>
                                      </>
                                    );
                                  })}
                                </IonRadioGroup>
                              </IonItem>
                            )}
                            {exerciseComplexity !== 'Hard' && exerciseComplexity !== '' && complex !== 'Hard' && (
                              <IonItem
                                className={`exercise_Create_ListItem ${isValid && 'ion-valid'} ${
                                  isValid === false && 'ion-invalid'
                                } ${isTouched && 'ion-touched'}`}>
                                <IonLabel className='exerciseLabel'>Hard Alternative: </IonLabel>
                                <IonRadioGroup
                                  onIonChange={handleHardAlternative}
                                  allowEmptySelection={true}
                                  value={hardAlternative ? hardAlternative : exercise.HardAlternative}>
                                  {hardAlternatives.length === 0 && (
                                    <IonItem>
                                      <IonLabel>No alternatives available</IonLabel>
                                      <IonButton
                                        onClick={() => {
                                          console.log('click');
                                          setAlternativeComplexity('Hard');
                                          setCreateAlternativeModal(true);
                                        }}
                                        className='primary'>
                                        Create Hard Alternative
                                      </IonButton>
                                    </IonItem>
                                  )}
                                  {hardAlternatives.map((alternative, key) => {
                                    return (
                                      <>
                                        <IonItem key={key}>
                                          <IonLabel>
                                            {alternative.Name} {' - '} {alternative.Complexity}
                                          </IonLabel>
                                          <IonRadio slot='end' value={alternative.id}></IonRadio>
                                        </IonItem>
                                      </>
                                    );
                                  })}
                                </IonRadioGroup>
                              </IonItem>
                            )}
                          </IonList>
                          <IonRow>
                            <IonCol size='6'></IonCol>
                            <IonCol size='6' className='ion-text-center'>
                              <IonButton className='primary' type='submit'>
                                Save changes
                              </IonButton>
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </form>
                    </div>
                  </IonCardContent>
                </IonCard>
              )}
            </IonRow>
          </IonGrid>
        </IonContent>
        <IonToast
          isOpen={isCompleted}
          message='Exercise successfully updated'
          duration={2500}
          position={'bottom'}
          color={'success'}></IonToast>
        <IonToast
          isOpen={isError}
          message={exerciseErrorMessage}
          duration={2500}
          position={'bottom'}
          color={'error'}></IonToast>
      </IonPage>
    </>
  );
};

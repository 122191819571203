// Import the functions you need from the SDKs you need
import firebase from 'firebase/app';
import 'firebase/functions';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  // apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  // authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  // projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  // storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET, //'dev-oryx-training.appspot.com',
  // messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  // appId: process.env.REACT_APP_FIREBASE_APP_ID,
  // measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  // databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,

  apiKey: 'AIzaSyDWAdY091CgjBGKHPu530-JG5zfpuL9z0Q',
  authDomain: 'training.oryx.app', //'oryx-training-app.firebaseapp.com',
  databaseURL: 'https://oryx-training-app-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'oryx-training-app',
  storageBucket: 'oryx-training-app.appspot.com',
  messagingSenderId: '420873696195',
  appId: '1:420873696195:web:b461df6375987428774376',
  measurementId: 'G-T2D2B110XS',
};

// Initialize Firebase
// export const myFirebase = initializeApp(firebaseConfig);
// const analytics = getAnalytics(myFirebase);

// Ensure that you do not initialize twice.
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  if (process.env.NODE_ENV === 'development') {
    console.log('Firebase initialized');
    console.log(firebaseConfig);
  }
  console.log('Firebase initialized');
  console.log(firebaseConfig);
}
export const myFirebase = firebase;

/** File selection input field for Web */
import { IonButton, IonItem } from '@ionic/react';
import { useState } from 'react';
import './index.css';

export interface IFileInputProps {
  onChange?: ((event: any) => void) | undefined;
  type: string;
  disabled?: boolean;
}

/** File selection input field
 * Styled to Ionic style as Ionic does not provide a styled file input widget.
 * Note for native IOS/Android this would need to change to the native
 * file selection widgets.
 */
export const FileInput = ({ onChange, type, disabled }: IFileInputProps) => {
  const [file, setFile] = useState('');

  const handleFileChange = (e: any) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const filename = e.target.files[0].name;
      setFile(filename);
      onChange && onChange(e);
    }
  };

  return (
    <IonItem lines='none' color={'--ion-color-light-blue100'}>
      <input className='inputfile' type='file' id='file' onChange={handleFileChange} multiple={false} />
      <IonButton disabled={disabled}>
        <label htmlFor='file'>Upload your {type}</label>
      </IonButton>
    </IonItem>
  );
};

import {
  IonButton,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonChip,
  IonCol,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonNote,
  IonRadio,
  IonRadioGroup,
  IonRow,
  IonTextarea,
  useIonAlert,
  useIonToast,
} from '@ionic/react';
import { pencilOutline } from 'ionicons/icons';
import React, { useState } from 'react';
import { useAuthContext } from '../../../contexts/authContext';
import { useExercises } from '../../../hooks/useExercises';
import { useFirebaseUpload } from '../../../hooks/useFirebaseUpload';
import { Exercise } from '../../../model';
import './index.css';
import { UploadArea } from './uploadArea';

export interface ICreateAlternativeModalProps {
  exerciseName: string;
  alternativeComplexity: string;
  setCreateAlternativeModal: (value: React.SetStateAction<boolean>) => void;
  exercise: Exercise;
}

export const CreateAlternativeModal = ({
  exerciseName,
  alternativeComplexity,
  setCreateAlternativeModal,
  exercise,
}: ICreateAlternativeModalProps) => {
  const { isError, errorMessage, clearError } = useFirebaseUpload();
  const [isTouched, setIsTouched] = useState(false);
  const [isValid, setIsValid] = useState<boolean>();

  const [exerciseDescription, setExerciseDescription] = useState<string>('');
  const [descriptionEdit, setDescriptionEdit] = useState<boolean>(false);
  const [thumbnail, setThumbnail] = useState('');
  const [video, setVideo] = useState('');
  const [exerciseEquipment, setExerciseEquipment] = useState<string[]>([]);
  const [coachingCues, setCoachingCues] = useState<string[]>([]);
  const [tags, setTags] = useState<string[]>([]);
  const [exerciseIntention, setExerciseIntention] = useState('');
  const [intentionEdit, setIntentionEdit] = useState<boolean>(false);
  const [Level, setLevel] = useState('');
  const exerciseComplexity = alternativeComplexity;
  const user = useAuthContext().state.userState?.user;
  const { createExercise, isCompleted, isExerciseError, exerciseErrorMessage, exerciseData } = useExercises(user?.uid);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [easyAlternative, setEasyAlternative] = useState<string>('');
  const [mediumAlternative, setMediumAlternative] = useState<string>('');
  const [hardAlternative, setHardAlternative] = useState<string>('');
  const [equipmentEdit, setEquipmentEdit] = useState<boolean>(false);
  const [coachingCuesEdit, setCoachingCuesEdit] = useState<boolean>(false);
  const [tagsEdit, setTagsEdit] = useState<boolean>(false);
  const [subtitle, setSubtitle] = useState<string>('');
  const [subtitleEdit, setSubtitleEdit] = useState<boolean>(false);
  const [presentAlert] = useIonAlert();

  const handleThumbnail = (thumbNailUuid: string) => {
    setThumbnail(thumbNailUuid);
  };

  const handleDescription = (ev: Event) => {
    setExerciseDescription((ev.target as HTMLInputElement).value);
  };
  const handleSubtitle = (ev: Event) => {
    setSubtitle((ev.target as HTMLInputElement).value);
  };

  const handleEquipment = (ev: Event) => {
    const equipmentArray = (ev.target as HTMLInputElement).value.split(',');
    setExerciseEquipment(equipmentArray);
  };

  const handleCoachingCues = (ev: Event) => {
    const coachingCuesArray = (ev.target as HTMLInputElement).value.split(',');
    setCoachingCues(coachingCuesArray);
  };
  const handleExerciseIntention = (ev: Event) => {
    setExerciseIntention((ev.target as HTMLInputElement).value);
  };

  const handleLevel = (ev: Event) => {
    setLevel((ev.target as HTMLInputElement).value);
  };

  const handleTags = (ev: Event) => {
    const tagsArray = (ev.target as HTMLInputElement).value.split(',');
    setTags(tagsArray);
  };

  const markTouched = () => {
    setIsTouched(true);
  };

  const [present] = useIonToast();

  const presentToast = (message: string, timer: number, color: string) => {
    present({
      message: message,
      duration: timer,
      position: 'bottom',
      color: color,
    });
  };

  const createExerciseObject = () => {
    const alternative = {
      Name: exerciseName,
      Description: exerciseDescription ? exerciseDescription : exercise?.Description,
      Subtitle: subtitle ? subtitle : exercise?.Subtitle,
      thumbnailURL: thumbnail,
      videoURL: video,
      Equipment: exerciseEquipment ? exerciseEquipment : exercise?.Equipment,
      CoachingCues: coachingCues ? coachingCues : exercise?.CoachingCues,
      Type: tags ? tags : exercise?.Type,
      Intention: exerciseIntention ? exerciseIntention : exercise?.Intention,
      Level: Level ? Level : exercise?.Level,
      Complexity: exerciseComplexity,
      EasyAlternative: easyAlternative,
      MediumAlternative: mediumAlternative,
      HardAlternative: hardAlternative,
      status: 'active',
    };
    return alternative;
  };

  // The actual creation once you click Submit in the form
  const create = async (e: React.FormEvent) => {
    e.preventDefault();
    setFormSubmitted(true);

    if (user) {
      console.log('everything is filled in');
      const alternative = createExerciseObject();
      // Perform the acutal file upload using the hook
      await createExercise(alternative)
        .then(() => {
          console.log('exercise created');
          presentToast('Exercise created', 2000, 'success');
          setCreateAlternativeModal(false);
        })
        .catch((error) => {
          console.log('error creating exercise', error);
          presentToast('Error creating exercise', 2000, 'danger');
        });
      // If the upload is completed, close the modal
    } else {
      console.log('Something is missing');
    }
  };
  return (
    <>
      <IonCardHeader>
        <IonCardTitle>{exerciseName}</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <div className='scrollable'>
          <form noValidate onSubmit={create}>
            <IonGrid fixed>
              {!exerciseName && (
                <IonRow className='disabled'>
                  <IonCol size='6'>
                    <UploadArea
                      type='image'
                      exerciseName={exerciseName}
                      disabled={true}
                      setThumbnail={setThumbnail}
                      setVideo={setVideo}
                    />
                  </IonCol>
                  <IonCol size='6'>
                    <UploadArea
                      type='video'
                      exerciseName={exerciseName}
                      disabled={true}
                      setThumbnail={setThumbnail}
                      setVideo={setVideo}
                    />
                  </IonCol>
                </IonRow>
              )}
              {exerciseName && (
                <>
                  <IonRow>
                    <IonCol size='6'>
                      <UploadArea
                        type='image'
                        exerciseName={exerciseName}
                        disabled={false}
                        setThumbnail={setThumbnail}
                        setVideo={setVideo}
                      />
                    </IonCol>
                    <IonCol size='6'>
                      <UploadArea
                        type='video'
                        exerciseName={exerciseName}
                        disabled={false}
                        setThumbnail={setThumbnail}
                        setVideo={setVideo}
                      />
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol size='6'>
                      <p>{thumbnail}</p>
                    </IonCol>
                    <IonCol size='6'>
                      <p>{video}</p>
                    </IonCol>
                  </IonRow>
                </>
              )}

              <IonList mode='ios' lines='none'>
                <IonItem
                  className={`exercise_Create_ListItem ${isValid && 'ion-valid'} ${
                    isValid === false && 'ion-invalid'
                  } ${isTouched && 'ion-touched'}`}>
                  <IonLabel className='exerciseLabel'>Exercise Subtitle: </IonLabel>
                  {!subtitleEdit && (
                    <>
                      <IonButton
                        icon-only
                        slot='end'
                        fill='clear'
                        onClick={() => {
                          setSubtitleEdit(true);
                        }}>
                        <IonIcon src={pencilOutline} slot='end' />
                      </IonButton>
                      <IonTextarea name='Subtitle' readonly autoGrow={true} wrap='soft' className='ion-text-end'>
                        {exercise.Subtitle}
                      </IonTextarea>
                    </>
                  )}
                  {subtitleEdit && (
                    <>
                      <IonTextarea
                        name='Subtitle'
                        required
                        autoGrow={true}
                        wrap='soft'
                        onIonChange={handleSubtitle}
                        onIonBlur={() => markTouched()}
                        onIonFocus={() => markTouched()}></IonTextarea>
                      <IonNote mode='ios' slot='helper'>
                        <small>{exercise.Subtitle}</small>
                      </IonNote>
                    </>
                  )}
                </IonItem>
                <IonItem
                  className={`exercise_Create_ListItem ${isValid && 'ion-valid'} ${
                    isValid === false && 'ion-invalid'
                  } ${isTouched && 'ion-touched'}`}>
                  <IonLabel className='exerciseLabel'>Exercise Description: </IonLabel>
                  {!descriptionEdit && (
                    <>
                      <IonButton
                        icon-only
                        slot='end'
                        fill='clear'
                        onClick={() => {
                          setDescriptionEdit(true);
                        }}>
                        <IonIcon src={pencilOutline} slot='end' />
                      </IonButton>
                      <IonTextarea name='Description' readonly autoGrow={true} wrap='soft' className='ion-text-end'>
                        {exercise.Description}
                      </IonTextarea>
                    </>
                  )}
                  {descriptionEdit && (
                    <>
                      <IonTextarea
                        name='Description'
                        required
                        autoGrow={true}
                        wrap='soft'
                        onIonChange={handleDescription}
                        onIonBlur={() => markTouched()}
                        onIonFocus={() => markTouched()}></IonTextarea>
                      <IonNote mode='ios' slot='helper'>
                        <small>{exercise.Description}</small>
                      </IonNote>
                    </>
                  )}
                </IonItem>
                <IonItem
                  className={`exercise_Create_ListItem ${isValid && 'ion-valid'} ${
                    isValid === false && 'ion-invalid'
                  } ${isTouched && 'ion-touched'}`}>
                  <IonLabel className='exerciseLabel'>Exercise Equipment: </IonLabel>
                  {!equipmentEdit && (
                    <>
                      <IonButton icon-only slot='end' fill='clear' onClick={() => setEquipmentEdit(true)}>
                        <IonIcon src={pencilOutline} slot='end' />
                      </IonButton>
                      <IonTextarea autoGrow={true} wrap='soft' className='ion-text-end'>
                        {exercise.Equipment.map((equipment) => (
                          <>
                            <IonChip key={equipment}> {equipment} </IonChip>
                          </>
                        ))}
                      </IonTextarea>
                    </>
                  )}
                  {equipmentEdit && (
                    <>
                      <IonInput
                        name='Equipment'
                        required
                        type='text'
                        clearInput={true}
                        onIonChange={handleEquipment}
                        onIonBlur={() => markTouched()}
                        onIonFocus={() => markTouched()}></IonInput>
                      <IonNote mode='md' slot='helper'>
                        <small>
                          {exercise.Equipment.join(', ')} &nbsp;&nbsp; - &nbsp;&nbsp;{' '}
                          <strong>Separate multiple equipments by a comma.</strong>
                        </small>
                      </IonNote>
                    </>
                  )}
                </IonItem>
                <IonItem
                  className={`exercise_Create_ListItem ${isValid && 'ion-valid'} ${
                    isValid === false && 'ion-invalid'
                  } ${isTouched && 'ion-touched'}`}>
                  <IonLabel className='exerciseLabel'>Exercise Tags: </IonLabel>
                  {!tagsEdit && (
                    <>
                      <IonButton icon-only slot='end' fill='clear' onClick={() => setTagsEdit(true)}>
                        <IonIcon src={pencilOutline} slot='end' />
                      </IonButton>
                      <IonTextarea autoGrow={true} wrap='soft' className='ion-text-end'>
                        {exercise.Type.map((type) => (
                          <>
                            <IonChip key={type}> {type} </IonChip>
                          </>
                        ))}
                      </IonTextarea>
                    </>
                  )}
                  {tagsEdit && (
                    <>
                      <IonInput
                        name='Type'
                        required
                        type='text'
                        clearInput={true}
                        onIonChange={handleTags}
                        onIonBlur={() => markTouched()}
                        onIonFocus={() => markTouched()}>
                        {exercise.Type.join(', ')}
                      </IonInput>
                      <IonNote mode='md' slot='helper'>
                        <small>
                          {exercise.Type.join(', ')} &nbsp;&nbsp; - &nbsp;&nbsp;{' '}
                          <strong>Separate multiple tags by a comma.</strong>
                        </small>
                      </IonNote>
                    </>
                  )}
                </IonItem>
                <IonItem
                  className={`exercise_Create_ListItem ${isValid && 'ion-valid'} ${
                    isValid === false && 'ion-invalid'
                  } ${isTouched && 'ion-touched'}`}>
                  <IonLabel className='exerciseLabel'>Coaching cues: </IonLabel>
                  {!coachingCuesEdit && (
                    <>
                      <IonButton icon-only slot='end' fill='clear' onClick={() => setCoachingCuesEdit(true)}>
                        <IonIcon src={pencilOutline} slot='end' />
                      </IonButton>
                      <IonTextarea autoGrow={true} wrap='soft' className='ion-text-end'>
                        {exercise.CoachingCues.map((cue) => (
                          <>
                            <IonChip key={cue}> {cue} </IonChip>
                          </>
                        ))}
                      </IonTextarea>
                    </>
                  )}
                  {coachingCuesEdit && (
                    <>
                      <IonInput
                        name='CoachingCues'
                        required
                        type='text'
                        clearInput={true}
                        onIonChange={handleCoachingCues}
                        onIonBlur={() => markTouched()}
                        onIonFocus={() => markTouched()}>
                        {exercise.CoachingCues.join(', ')}
                      </IonInput>
                      <IonNote mode='md' slot='helper'>
                        <small>
                          {exercise.CoachingCues.join(', ')} &nbsp;&nbsp; - &nbsp;&nbsp;{' '}
                          <strong>Separate multiple cues by a comma.</strong>
                        </small>
                      </IonNote>
                    </>
                  )}
                </IonItem>
                <IonItem
                  className={`exercise_Create_ListItem ${isValid && 'ion-valid'} ${
                    isValid === false && 'ion-invalid'
                  } ${isTouched && 'ion-touched'}`}>
                  <IonLabel className='exerciseLabel'>Exercise Intention:</IonLabel>
                  {!intentionEdit && (
                    <>
                      <IonButton
                        icon-only
                        slot='end'
                        fill='clear'
                        onClick={() => {
                          setIntentionEdit(true);
                        }}>
                        <IonIcon src={pencilOutline} slot='end' />
                      </IonButton>
                      <IonTextarea name='Description' readonly autoGrow={true} wrap='soft' className='ion-text-end'>
                        {exercise.Intention}
                      </IonTextarea>
                    </>
                  )}
                  {intentionEdit && (
                    <>
                      <IonTextarea
                        name='Intention'
                        required
                        autoGrow={true}
                        wrap='soft'
                        onIonChange={handleExerciseIntention}
                        onIonBlur={() => markTouched()}
                        onIonFocus={() => markTouched()}>
                        {exercise.Intention ? exercise.Intention : 'Add exercise intention'}
                      </IonTextarea>
                      <IonNote mode='ios' slot='helper'>
                        <small>{exercise.Intention}</small>
                      </IonNote>
                    </>
                  )}
                </IonItem>
                <IonItem
                  className={`exercise_Create_ListItem ${isValid && 'ion-valid'} ${
                    isValid === false && 'ion-invalid'
                  } ${isTouched && 'ion-touched'}`}>
                  <IonLabel className='exerciseLabel'>Exercise Level:</IonLabel>
                  <IonRadioGroup onIonChange={handleLevel} value={Level ? Level : exercise.Level}>
                    <IonItem>
                      <IonLabel>Beginner</IonLabel>
                      <IonRadio slot='end' value='Beginner'></IonRadio>
                    </IonItem>
                    <IonItem>
                      <IonLabel>Advanced</IonLabel>
                      <IonRadio slot='end' value='Advanced'></IonRadio>
                    </IonItem>
                    <IonItem>
                      <IonLabel>Expert</IonLabel>
                      <IonRadio slot='end' value='Expert'></IonRadio>
                    </IonItem>
                  </IonRadioGroup>
                </IonItem>
                <IonItem
                  className={`exercise_Create_ListItem ${isValid && 'ion-valid'} ${
                    isValid === false && 'ion-invalid'
                  } ${isTouched && 'ion-touched'}`}>
                  <IonLabel className='exerciseLabel'>Exercise Complexity:</IonLabel>
                  <IonRadioGroup value={alternativeComplexity}>
                    <IonItem>
                      <IonLabel>{alternativeComplexity}</IonLabel>
                      <IonRadio slot='end' value={alternativeComplexity}></IonRadio>
                    </IonItem>
                  </IonRadioGroup>
                </IonItem>
              </IonList>
              <IonRow>
                <IonCol size='6'></IonCol>
                <IonCol size='6' className='ion-text-center'>
                  <IonButton className='primary' type='submit'>
                    Create Exercise
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </form>
        </div>
      </IonCardContent>
    </>
  );
};

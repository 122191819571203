import { IonAlert, IonButton, IonCol, IonGrid, IonIcon, IonImg, IonRow, IonSpinner, IonText } from '@ionic/react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { FileInput } from '.';
import imageUploadIcon from '../../../assets/icons/imageUploadIcon.svg';
import videoUploadIcon from '../../../assets/icons/videoUploadIcon.svg';
import './index.css';

import { trashBinOutline } from 'ionicons/icons';
import { useAuthContext } from '../../../contexts/authContext';
import { useExercises } from '../../../hooks/useExercises';
import { FileData, useFirebaseUpload } from '../../../hooks/useFirebaseUpload';

export interface IUploadAreaProps {
  type: string;
  exerciseName: string;
  disabled: boolean;
  setThumbnail: (uuid: string) => void;
  setVideo: (uuid: string) => void;
  thumbnail?: string;
  video?: string;
}
export const UploadArea = ({
  type,
  exerciseName,
  disabled,
  setThumbnail,
  setVideo,
  video,
  thumbnail,
}: IUploadAreaProps) => {
  const { dataResponse, downloadURL, isLoading, isError, errorMessage, progress, setUploadData, clearError } =
    useFirebaseUpload();
  const user = useAuthContext().state.userState?.user;
  const { doDeleteMedia, isDeleted, isExerciseError, exerciseErrorMessage } = useExercises(user?.uid);
  const [file, setFile] = useState<FileData>();
  const [fileName, setFileName] = useState<string>('');
  const [contentType, setContentType] = useState<string>('');
  const [data, setData] = useState<typeof dataResponse>();
  const [uuid, setUuid] = useState<string>('');
  const [contentURL, setContentURL] = useState<string>('');

  const handleFileSelect = (e: any) => {
    e.target.files.length > 0 && setFile(e.target.files[0]);
    console.log('e.target.files[0]', e.target.files[0].name);
    setFileName(e.target.files[0].name);
    setUuid(uuidv4());

    // e.target.files.length > 0 && setFile(e.target.files[0]);
    if (exerciseName === '') {
      alert('Please enter an exercise name');
    }
  };

  function getUUIDFromURL(url: string) {
    const urlArray = url.split('%2F');
    const uuid = urlArray[urlArray.length - 1].split('?')[0];
    setUuid(uuid);
  }

  useEffect(() => {
    if (video) {
      getUUIDFromURL(video);
      setContentURL(video);
      setContentType('video');
    }
    if (thumbnail) {
      getUUIDFromURL(thumbnail);
      setContentURL(thumbnail);
      setContentType('image');
    }
  }, [video, thumbnail]);

  useEffect(() => {
    if (isDeleted) {
      setFile(undefined);
      setFileName('');
      setData(undefined);
      setUuid('');
    }
  }, [isDeleted]);

  useEffect(() => {
    if (!!user && !!file) {
      const user_id = user.uid;
      // Perform the acutal file upload using the hook
      setUploadData({ user_id, file, type, exerciseName, uuid });
    }
  }, [file]);

  useEffect(() => {
    if (dataResponse) {
      setData(dataResponse);
    }
    switch (dataResponse?.metaData.contentType) {
      case 'image/jpeg':
      case 'image/png':
      case 'image/gif':
        setContentType('image');
        setThumbnail(uuid);
        break;

      case 'video/quicktime':
      case 'video/mp4':
      case 'video/avi':
      case 'video/mov':
      case 'video/mpeg':
      case 'video/mpeg4':
      case 'video/mpeg-4':
        setContentType('video');
        setVideo(uuid);
        break;

      default:
        setContentType('');
    }
  }, [dataResponse]);

  // drag state
  const [dragActive, setDragActive] = React.useState(false);

  // handle drag events
  const handleDrag = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setFile(e.dataTransfer.files[0]);
      setFileName(e.dataTransfer.files[0].name);
      setUuid(uuidv4());
    }
  };

  return (
    <>
      <IonGrid fixed className={dragActive ? 'drag-active' : 'uploadArea_box'}>
        <IonAlert
          isOpen={isExerciseError ? true : false}
          onDidDismiss={() => clearError()}
          message={exerciseErrorMessage}
          buttons={['OK']}
        />
        {!data && !contentURL && (
          <>
            <div
              onDragEnter={handleDrag}
              onSubmit={(e) => e.preventDefault()}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={handleDrop}>
              <IonRow className='ion-justify-content-center '>
                {type === 'image' && <IonIcon src={imageUploadIcon} className='uploadIcon' />}
                {type === 'video' && <IonIcon src={videoUploadIcon} className='uploadIcon' />}
              </IonRow>
              {!isLoading && (
                <>
                  <IonRow className='ion-justify-content-center '>
                    <IonText>
                      <FileInput type={type} onChange={handleFileSelect} disabled={disabled} />
                      or drag and drop.
                    </IonText>
                  </IonRow>
                  <IonRow className='ion-justify-content-center '>
                    <IonText>
                      {type === 'image' && <small>JPG, PNG or GIF up to 25MB</small>}
                      {type === 'video' && <small>MP4 up to 25MB</small>}
                    </IonText>
                  </IonRow>
                </>
              )}

              {isLoading && (
                <>
                  <IonRow className='ion-justify-content-center '>
                    <IonSpinner name='bubbles' color='primary' />
                  </IonRow>
                </>
              )}
            </div>
          </>
        )}
        {downloadURL && contentType === 'image' && data && (
          <>
            <IonImg src={downloadURL.downloadURL} alt='' className='createExercise_ThumbnailPreview' />
          </>
        )}
        {contentURL && contentType === 'image' && (
          <>
            <IonImg src={contentURL} alt='' className='createExercise_ThumbnailPreview' />
          </>
        )}
        {downloadURL && contentType === 'video' && data && (
          <>
            <video controls className='createExercise_VideoPreview'>
              <source src={downloadURL.downloadURL} type='video/mp4' />
              <track kind='captions' />
            </video>
          </>
        )}
        {contentURL && contentType === 'video' && (
          <>
            <video controls className='createExercise_VideoPreview'>
              <source src={contentURL} type='video/mp4' />
              <track kind='captions' />
            </video>
          </>
        )}

        {downloadURL && data && (
          <>
            <IonRow>
              <IonCol size='12'>
                <IonButton
                  fill='clear'
                  size='small'
                  className='errorIcon'
                  onClick={() => {
                    console.log('click!');
                    doDeleteMedia(uuid, contentType);
                    contentType === 'image' ? setThumbnail('') : setVideo('');
                  }}>
                  <IonIcon src={trashBinOutline} slot='icon-only' />
                </IonButton>
              </IonCol>
            </IonRow>
          </>
        )}
        {contentURL && (
          <>
            <IonRow>
              <IonCol size='12'>
                <IonButton
                  fill='clear'
                  size='small'
                  className='errorIcon'
                  onClick={() => {
                    console.log('click!');
                    doDeleteMedia(uuid, contentType);
                    contentType === 'image' ? setThumbnail('') : setVideo('');
                    setContentURL('');
                  }}>
                  <IonIcon src={trashBinOutline} slot='icon-only' />
                </IonButton>
              </IonCol>
            </IonRow>
          </>
        )}
      </IonGrid>
    </>
  );
};

/** Main url routes for the application */
import { IonRouterOutlet, IonSplitPane } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Menu } from './components/ui';
import { useAuthContext } from './contexts/authContext';
import { GuardedRoute } from './contexts/authContext/GuardedRoute';
import { GuestRoute } from './contexts/authContext/GuestRoute';
import { UserInfoProvider } from './hooks/auth/userInfoContext';
import { LoginPage } from './pages/auth/loginPage';
import { NewPasswordPage } from './pages/auth/NewPasswordPage';
import { ResetPasswordPage } from './pages/auth/ResetPasswordPage';
import { CreateExercisePage, ExerciseDetailPage, ExercisePage } from './pages/exercises';
// import { CreateExercisePage } from './pages/exercises/createExercise';
import { HomePage } from './pages/Home';
/** Main url routes for the application
 * FirebaseAuthConsumer provides the userId parameter to the sub pages when a user is logged in.
 * If you are not logged in only the home and login page is rendered.
 */
export const Routes = () => {
  const { isAuthorized } = useAuthContext();
  return (
    <IonReactRouter>
      {/* Note: the Nav element is needed for the side menu to know where to display itself */}
      {/* <IonNav id='main' /> */}
      {/* <Menu /> */}
      {!isAuthorized() ? (
        <IonRouterOutlet>
          <GuestRoute path='/'>
            <LoginPage />
          </GuestRoute>
          <GuestRoute path='/login'>
            <LoginPage />
          </GuestRoute>
          <GuestRoute path='/resetPassword'>
            <ResetPasswordPage />
          </GuestRoute>
          <GuestRoute path='/newPassword'>
            <NewPasswordPage />
          </GuestRoute>
        </IonRouterOutlet>
      ) : (
        <IonSplitPane contentId='main'>
          <Menu />
          <IonRouterOutlet id='main'>
            <UserInfoProvider>
              <GuardedRoute path='/'>
                <HomePage />
              </GuardedRoute>
              <GuardedRoute path='/exercises'>
                <ExercisePage />
              </GuardedRoute>
              <GuardedRoute path='/exercises/create'>
                <CreateExercisePage />
              </GuardedRoute>
              <GuardedRoute path='/exercises/exercise/:id'>
                <ExerciseDetailPage />
              </GuardedRoute>
            </UserInfoProvider>
          </IonRouterOutlet>
        </IonSplitPane>
      )}
    </IonReactRouter>
  );
};

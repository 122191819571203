/*
 * Created by ORYX Movement Solutions © 2022
 * ==================================================================
 *
 * Loginpage.
 */

import { IonContent, IonPage } from '@ionic/react';
import { LoginForm } from './forms';
import './index.css';

export const LoginPage: React.FC = () => {
  return (
    <IonPage>
      <IonContent fullscreen className='loginPage'>
        <LoginForm />
      </IonContent>
    </IonPage>
  );
};

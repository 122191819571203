/** Hook for Firebase authentication */
import 'firebase/auth';
import { myFirebase } from '../../config/firebaseConfig';
import { User, UserRole } from '../../model/user.model';

const signOut = async (): Promise<void> => {
  return await myFirebase.auth().signOut();
};

const signInWithTwitter = async (): Promise<void> => {
  const providerInstance = new myFirebase.auth.TwitterAuthProvider();
  await myFirebase.auth().signInWithPopup(providerInstance);
};

const signInWithGoogle = async (): Promise<void> => {
  const providerInstance = new myFirebase.auth.GoogleAuthProvider();
  await myFirebase.auth().signInWithPopup(providerInstance);
};

const signInWithFacebook = async (): Promise<void> => {
  const providerInstance = new myFirebase.auth.FacebookAuthProvider();
  await myFirebase.auth().signInWithPopup(providerInstance);
};

const signInWithEmailAndPassword = async (email: string, password: string): Promise<void> => {
  await myFirebase.auth().signInWithEmailAndPassword(email, password);
};

const createUserWithEmailAndPassword = async (email: string, password: string): Promise<void> => {
  await myFirebase.auth().createUserWithEmailAndPassword(email, password);
};

function currentUser(): User | null {
  return myFirebase.auth().currentUser;
}

//naming convention is odd, should be something along the lines of 'requestResetPassword'
const resetPassword = async (email: string): Promise<void> => {
  const actionCodeSettings = {
    url: `http://localhost:8100`,
    handleCodeInApp: true,
  };
  return myFirebase
    .auth()
    .sendPasswordResetEmail(email, actionCodeSettings)
    .then()
    .catch((error: { code: any; message: any }) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode, errorMessage);
    });
};

const confirmPasswordReset = async (confirmationCode: string, newPassword: string): Promise<boolean> => {
  return myFirebase
    .auth()
    .confirmPasswordReset(confirmationCode, newPassword)
    .then(() => {
      return true;
    })
    .catch(() => {
      return false;
    });
};

/** Authorization, get role set by the firebase Admin SDK
 * The role is set as Custom claim on the access token
 */
const getRole = async (): Promise<UserRole> => {
  const idTokenResult = await currentUser()?.getIdTokenResult();
  const role = idTokenResult?.claims?.role;
  return role ? role : UserRole.NONE;
};

type AuthChangeCallback = (user: User | null) => any;

function onAuthChanged(callback: AuthChangeCallback): void {
  myFirebase.auth().onAuthStateChanged(callback);
}

export type UseAuthProps = {
  signInWithEmailAndPassword: (email: string, password: string) => Promise<void>;
  createUserWithEmailAndPassword: (email: string, password: string) => Promise<void>;
  signInWithGoogle: () => Promise<void>;
  signInWithFacebook: () => Promise<void>;
  signInWithTwitter: () => Promise<void>;
  getRole: () => Promise<UserRole>;
  signOut: () => Promise<void>;
  onAuthChanged: (callback: AuthChangeCallback) => void;
  currentUser: () => User | null;
  resetPassword: (email: string) => Promise<void>;
  confirmPasswordReset: (confirmationCode: string, newPassword: string) => Promise<boolean>;
};

export const useAuth: UseAuthProps = {
  createUserWithEmailAndPassword: createUserWithEmailAndPassword,
  signInWithEmailAndPassword: signInWithEmailAndPassword,
  signInWithGoogle: signInWithGoogle,
  signInWithTwitter: signInWithTwitter,
  signInWithFacebook: signInWithFacebook,
  getRole: getRole,
  signOut: signOut,
  onAuthChanged: onAuthChanged,
  currentUser: currentUser,
  resetPassword: resetPassword,
  confirmPasswordReset: confirmPasswordReset,
};

/** Main menu shown on the top right */
import {
  IonButton,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonToolbar,
} from '@ionic/react';
import {
  barbellOutline,
  chevronBackCircleOutline,
  chevronForwardCircleOutline,
  homeOutline,
  peopleOutline,
  settingsOutline,
} from 'ionicons/icons';
import * as React from 'react';
import { useState } from 'react';
import { useLocation } from 'react-router';
import icon from '../../assets/icon.png';
import logo from '../../assets/logo.png';
import { useAuthContext } from '../../contexts/authContext';
import './menu.css';

/** Main menu shown top-left
 * Note: must be linked to the "Nav" item with the same id="main"
 * to be able to be visible.
 * Also, every content item on every page has the same id="main", not
 * fully sure if that is needed.
 */

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
  status: string;
}

const appPages: AppPage[] = [
  {
    title: 'Home',
    url: '/',
    iosIcon: homeOutline,
    mdIcon: homeOutline,
    status: 'active',
  },
  {
    title: 'Exercises',
    url: '/exercises',
    iosIcon: barbellOutline,
    mdIcon: barbellOutline,
    status: 'active',
  },
  {
    title: 'Customers',
    url: '/dashboard',
    iosIcon: peopleOutline,
    mdIcon: peopleOutline,
    status: 'disabled',
  },
  {
    title: 'Settings',
    url: '/dashboard',
    iosIcon: settingsOutline,
    mdIcon: settingsOutline,
    status: 'disabled',
  },
];
export const Menu: React.FC = () => {
  const { state, signOut } = useAuthContext();
  const location = useLocation();
  const [size, setSize] = useState('small');

  return (
    <IonMenu contentId='main' type='push' className={size}>
      {size == 'small' ? (
        <>
          <IonContent forceOverscroll={false} id='main' className='menu'>
            <IonHeader mode='ios' className='oryx_header ion-no-border'>
              <IonImg src={icon} className='header_logo_small' />
            </IonHeader>

            {!!state.userState && (
              <>
                <IonList lines='none' color='white'>
                  {appPages.map((appPage, _) => {
                    let menuClassNames = '';
                    let color = '';
                    if (appPage.status === 'disabled') {
                      menuClassNames = 'disabled';
                      color = 'disabled';
                    } else if (location.pathname === appPage.url) {
                      menuClassNames = 'selected';
                      color = 'primary';
                    }
                    return (
                      <IonMenuToggle key={appPage.title} autoHide={false}>
                        <IonItem
                          className={menuClassNames}
                          routerLink={appPage.url}
                          routerDirection='none'
                          lines='none'
                          detail={false}>
                          <IonIcon ios={appPage.iosIcon} md={appPage.mdIcon} color={color} />
                        </IonItem>
                      </IonMenuToggle>
                    );
                  })}
                  {/* <IonItem onClick={() => signOut()}>
                    <IonIcon slot='start' icon={logOutOutline} />
                  </IonItem> */}
                </IonList>
              </>
            )}
          </IonContent>
          <IonFooter className='ion-no-border'>
            <IonToolbar>
              <IonButton slot='start' onClick={() => setSize('large')} fill='clear' className='menuToggleButton'>
                <IonIcon icon={chevronForwardCircleOutline} />
              </IonButton>
            </IonToolbar>
          </IonFooter>
        </>
      ) : (
        <>
          <IonContent forceOverscroll={false} id='main'>
            <IonHeader mode='md' className='oryx_header ion-no-border'>
              <IonImg src={logo} className='header__logo' />
            </IonHeader>

            {!!state.userState && (
              <>
                <IonList lines='inset'>
                  {appPages.map((appPage) => {
                    let menuClassNames = '';
                    if (appPage.status === 'disabled') {
                      menuClassNames = 'disabled';
                    } else if (location.pathname === appPage.url) {
                      menuClassNames = 'selected';
                    }
                    return (
                      <IonMenuToggle key={appPage.title} autoHide={false}>
                        <IonItem
                          className={menuClassNames}
                          routerLink={appPage.url}
                          routerDirection='none'
                          lines='none'
                          detail={false}>
                          <IonIcon slot='start' ios={appPage.iosIcon} md={appPage.mdIcon} />
                          <IonLabel>{appPage.title}</IonLabel>
                        </IonItem>
                      </IonMenuToggle>
                    );
                  })}
                </IonList>
              </>
            )}
          </IonContent>
          <IonFooter className='ion-no-border'>
            <IonToolbar>
              <IonButton slot='start' onClick={() => setSize('small')} fill='clear' className='menuToggleButton'>
                <IonIcon icon={chevronBackCircleOutline} />
              </IonButton>
            </IonToolbar>
          </IonFooter>
        </>
      )}
    </IonMenu>
  );
};

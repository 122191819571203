import { IonButton, IonChip, IonCol, IonContent, IonGrid, IonPage, IonRow, IonSearchbar, IonText } from '@ionic/react';
import { useMemo } from 'react';
import { useHistory } from 'react-router';
import { ORYXHeader } from '../../components/ui';
import { useAuthContext } from '../../contexts/authContext';
import { useExercises } from '../../hooks/useExercises';
import { useSearch } from '../../hooks/useSearch';
import { Exercise, exerciseCollection } from '../../model';
import { ExercisePreviewCard } from './components';
import './index.css';

const sortExerciseData = (exerciseData: exerciseCollection): Exercise[] => {
  // Create array of tuples (id, exercise)
  const items: [string, Exercise][] = Object.keys(exerciseData).map((id: string) => {
    return [id, exerciseData[id]];
  });

  // Sort the array based on the second element (exercise.dateCreated.seconds * 1000) (newest first, oldest last)
  const exercisesSorted = items.sort((first, second) => {
    const a = second[1].dateCreated.seconds * 1000;
    const b = first[1].dateCreated.seconds * 1000;
    return a - b;
  });

  return exercisesSorted.map(([_id, exercise]) => exercise);
};

function filterTagList(value: any) {
  const list = [];
  const exercise = value[1];
  const types = exercise.Type;

  list.push(types.map((type: string) => type));
  return list;
}

const createTagList = (exerciseData: exerciseCollection) => {
  const values = Object.values(exerciseData);

  const map = values.map((value) => {
    const types = value.Type;
    return types.map((type: string) => type);
  });
  const flatMap = map.flat();

  const unique = [...new Set(flatMap)];

  return unique;
};

export const ExercisePage = () => {
  const history = useHistory();
  const user = useAuthContext().state.userState?.user;
  const { createExercise, exerciseData, exerciseDatabaseLoading } = useExercises(user?.uid);

  const exerciseList = useMemo(() => sortExerciseData(exerciseData!), [exerciseData]);
  const tagList = useMemo(() => createTagList(exerciseData!), [exerciseData]);

  const options = {
    includeScore: true,
    useExtendedSearch: true,
    keys: ['Name', 'Type'],
  };
  const { onSearch, query, searchResult } = useSearch(options, exerciseList);

  return (
    <IonPage id='main'>
      <ORYXHeader />
      <IonContent fullscreen className='appBackground'>
        <IonGrid fixed>
          <IonRow className='ion-justify-content-center'>
            <IonCol className='ion-padding ion-text-center'>
              <IonButton
                className='primary'
                onClick={() => {
                  history.push('/exercises/create');
                }}>
                create new exercise
              </IonButton>
            </IonCol>

            <IonCol className='ion-align-self-center'>
              <IonSearchbar
                value={query}
                animated={true}
                mode='md'
                onIonChange={(e) => {
                  console.log('e:', e);
                  onSearch(e);
                }}
                showClearButton='focus'></IonSearchbar>
            </IonCol>
          </IonRow>
          <IonRow className='ion-justify-content-center '>
            <IonCol className='ion-text-center ion-align-self-center' size='10'>
              {tagList.map((tag, key) => {
                return (
                  <IonChip
                    key={key}
                    onClick={() => {
                      console.log('clicked on tag: ', tag);
                      onSearch(tag);
                    }}>
                    {tag}
                  </IonChip>
                );
              })}
            </IonCol>
          </IonRow>
          <IonRow>
            {exerciseDatabaseLoading && (
              <IonCol className='ion-text-center'>
                <IonText color='primary'>
                  <h2>Loading exercises...</h2>
                </IonText>
              </IonCol>
            )}
            {exerciseList.length === 0 && (
              <IonCol className='ion-text-center'>
                <IonText color='primary'>
                  <h2>No exercises found. Start by creating one!</h2>
                </IonText>
              </IonCol>
            )}
            {searchResult.map((exercise, key) => {
              const oefening = exercise ? exerciseData![exercise.id] : undefined;
              return (
                <IonCol size='6' key={key}>
                  <ExercisePreviewCard exercise={oefening} />
                </IonCol>
              );
            })}
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

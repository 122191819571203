/** Upload the csv file to Firebase Cloud Storage */
import firebase from 'firebase/app';
import 'firebase/storage';
import { useEffect, useState } from 'react';
import { myFirebase } from '../config/firebaseConfig';

export type UploadDataResponse = { metaData: firebase.storage.FullMetadata } | undefined;
export type ProgressResponse = { value: number } | undefined | null;
export type DataAsDataUrl = { dataUrl: string; format: string };
export type FileData = File | DataAsDataUrl;
export type UploadSource = { user_id: string; file: FileData; type: string; exerciseName: string; uuid: string };

// the firebase reference to cloud storage
const storageRef = myFirebase.storage().ref();

/** Hook to upload files to the default Firebase Cloud Storage bucket
 * setUploadData to provide the file to upload;
 * dataResponse is the file metadata returned from the upload.
 */
export function useFirebaseUpload(): {
  dataResponse: UploadDataResponse;
  isLoading: boolean;
  isError: boolean;
  errorMessage: string;
  progress: ProgressResponse;
  setUploadData: (value: UploadSource) => void;
  // setUploadData: React.Dispatch<React.SetStateAction<UploadSource | undefined>>;
  clearError: () => void;
  downloadURL: { downloadURL: string } | undefined;
} {
  // the data from the file upload response
  const [dataResponse, setDataResponse] = useState<UploadDataResponse>();
  const [downloadURL, setDownloadURL] = useState<{ downloadURL: string }>();

  // sets properties (e.g. filename) on the file to be uploaded
  const [uploadData, setUploadData] = useState<UploadSource>();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [progress, setProgress] = useState<ProgressResponse>(null);

  // Called to close the error Toast
  const clearError = () => {
    setIsError(false);
  };

  // this function will only be called when the properties in the dependency array
  // [uploadData] changes
  useEffect(() => {
    function uploadPath(uuid: string, type: string): string {
      console.log('uploadPath', uuid, type);
      switch (type) {
        case 'image/jpeg':
          return `thumbnails/${uuid}`;
        case 'image/png':
          return `thumbnails/${uuid}`;
        case 'image/gif':
          return `thumbnails/${uuid}`;

        case 'video/quicktime':
          return `videos/${uuid}`;
        case 'video/mp4':
          return `videos/${uuid}`;
        case 'video/avi':
          return `videos/${uuid}`;
        case 'video/mov':
          return `videos/${uuid}`;
        case 'video/mpeg':
          return `videos/${uuid}`;
        case 'video/mpeg4':
          return `videos/${uuid}`;
        case 'video/mpeg-4':
          return `videos/${uuid}`;
        default:
          return `undefined/${uuid}`;
      }
    }

    const setUp = ({ user_id, file, type, exerciseName, uuid }: UploadSource): firebase.storage.UploadTask => {
      const metadata = {
        customMetadata: {
          exerciseName: exerciseName,
          uploaded_by: user_id,
        },
      };

      if (file instanceof File) {
        const path = uploadPath(uuid, file.type);

        // Setting the firebase properties for the file upload
        // triggering the actual upload.
        const ref = storageRef.child(path);
        console.log('ref', ref);
        return ref.put(file, metadata);
      } else {
        const v = file as DataAsDataUrl;
        const path = uploadPath(uuid, type);

        // Setting the firebase properties for the file upload
        // triggering the actual upload.
        const ref = storageRef.child(path);
        return ref.putString(v.dataUrl, 'data_url', metadata);
      }
    };

    const upload = async (uploadData: UploadSource) => {
      setIsError(false);
      setIsLoading(true);

      setProgress({ value: 0 });

      // handle a file upload or a dataUrl upload
      const uploadTask = setUp(uploadData);

      // wrap the whole thing in a try catch block to update the error state
      try {
        // tracking the state of the upload to assist in updating the
        // application UI
        uploadTask.on(
          firebase.storage.TaskEvent.STATE_CHANGED,
          (progress) => {
            const value = progress.bytesTransferred / progress.totalBytes;
            console.log('Upload is ' + value * 100 + '% done');
            setProgress({ value });
          },
          (error) => {
            setIsLoading(false);
            setErrorMessage(error.message);
            console.log(error);
          },
          () => {
            setIsError(false);
            setIsLoading(false);
            setDataResponse({
              metaData: uploadTask.snapshot.metadata,
            });
            setProgress(null);
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              setDownloadURL({
                downloadURL,
              });
            });
          },
        );
      } catch (error) {
        setIsLoading(false);
        setIsError(true);
      }
    };

    uploadData && upload(uploadData);
  }, [uploadData]);

  return {
    dataResponse,
    downloadURL,
    isLoading,
    isError,
    errorMessage,
    progress,
    setUploadData,
    clearError,
  };
}

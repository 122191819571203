/* ORYX App 2.0
 *
 * Created by ORYX Movement Solutions © 2022
 * ==================================================================
 *
 * Header Component, used on all pages.
 */
import {
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonPopover,
  IonProgressBar,
  IonToolbar,
  useIonAlert,
} from '@ionic/react';
import {
  arrowBack,
  chevronDownOutline,
  chevronForwardOutline,
  closeCircleOutline,
  languageOutline,
} from 'ionicons/icons';
import * as React from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router';
import Dutch from '../../assets/countries/netherlands.svg';
import English from '../../assets/countries/united-kingdom.svg';
import { useAuthContext } from '../../contexts/authContext';
import './index.css';
// import { YourProfile } from './YourProfile';
// import { YourSubscriptions } from './YourSubscriptions';
// import { useUserInfoContext } from '../userInfoContext';
// import { MagneticFieldData, NativeSensorControl } from '../../capacitor_plugins/NativeSensorControlPluginClass';
import { useUserInfoContext } from '../../hooks/auth/userInfoContext';
import { updateArrayCache } from '../../utilities/CacheController';

type HeaderProps = {
  loading?: boolean;
  progress?: number;
  backTarget?: string;
  children?: React.ReactNode;
  unsavedChanges?: boolean;
};

interface userMenuItem {
  id: number;
  name: string;
  selected: boolean;
  key: string;
  modal?: string;
  url?: string;
}

interface languageMenuItem {
  id: number;
  name: string;
  selected: boolean;
  key: string;
  icon: string;
  status: string;
}

const userMenuItems: userMenuItem[] = [
  // { id: 2, name: 'Your subscriptions', selected: false, key: 'userMenu', modal: 'YourSubscriptions' },
];

const languageMenuItems: languageMenuItem[] = [
  {
    id: 1,
    name: 'English',
    selected: true,
    key: 'userMenu',
    icon: English,
    status: 'active',
  },
  {
    id: 2,
    name: 'Dutch',
    selected: false,
    key: 'userMenu',
    icon: Dutch,
    status: 'disabled',
  },
];

/** Main header used on all pages
 * Providing the back button if needed, logo, and menu button.
 * Shows the Login button instead of the menu if not authenticated.
 */
export const ORYXHeader = ({ loading, progress, backTarget, unsavedChanges }: HeaderProps) => {
  const history = useHistory();
  const user = useAuthContext().state.userState?.user;
  const role = useAuthContext().state.userState?.role;
  const { signOut } = useAuthContext();
  const userInfo = useUserInfoContext().userInfo;
  const goBack = (backTarget: string) => {
    history.push(backTarget);
  };
  const [presentAlert] = useIonAlert();

  const [showUserMenu, setShowUserMenu] = useState({
    showUserMenu: false,
    event: undefined,
  });
  const [showLanguageMenu, setShowLanguageMenu] = useState({
    showLanguageMenu: false,
    event: undefined,
  });

  const [showUserInfo, setShowUserInfo] = useState('');

  // debug stuff
  // const [debug1, setDebug1] = useState<MagneticFieldData>({ magneticFieldAccuracy: 0, magneticFieldValue: 0 });

  return (
    <>
      {/* Used for userMenu dropdown.... */}
      <IonPopover
        class='my-custom-class'
        event={showUserMenu.event}
        isOpen={showUserMenu.showUserMenu}
        onDidDismiss={() => setShowUserMenu({ showUserMenu: false, event: undefined })}
        mode='ios'>
        <IonList className='userMenu_List'>
          {userMenuItems.map((userMenuItem, id) => {
            if ('modal' in userMenuItem) {
              return (
                <IonItem
                  key={userMenuItem.id}
                  onClick={() => {
                    setShowUserInfo(userMenuItem.modal!);
                    setShowUserMenu({ showUserMenu: false, event: undefined });
                  }}
                  button
                  routerDirection='none'
                  detail={false}>
                  <IonLabel className='TextAlign'>{userMenuItem.name}</IonLabel>
                </IonItem>
              );
            } else {
              return (
                <IonItem
                  key={userMenuItem.id}
                  onClick={() => {
                    setShowUserMenu({ showUserMenu: false, event: undefined });
                  }}
                  button
                  routerLink={userMenuItem.url}
                  routerDirection='none'
                  detail={false}>
                  <IonLabel className='TextAlign'>{userMenuItem.name}</IonLabel>
                </IonItem>
              );
            }
          })}
          {role == 'ORYX_Admin' && (
            <IonItem button routerLink='/ORYX_Admin' routerDirection='forward' detail={false}>
              <IonLabel className='TextAlign'>ORYX Admin</IonLabel>
            </IonItem>
          )}
          <IonItem button onClick={() => signOut()} detail={false}>
            <IonLabel className='TextAlign'>Sign out</IonLabel>
          </IonItem>

          {/* 
          ====================================================================
          TEST BUTTON FOR DEVELOPMENT ONLY
          ====================================================================
          */}
          {/* <IonItem
            button
            onClick={async () => {
              await NativeSensorControl.startMagneticFieldMeasuring();
              await NativeSensorControl.on('magneticFieldData', (data: MagneticFieldData) => {
                setDebug1(data);
              });

            }}
            detail={false}>
            <IonLabel className='TextAlign'>Test button</IonLabel>
          </IonItem>

          <IonItem
            button
            onClick={async () => {
              await NativeSensorControl.stopMagneticFieldMeasuring();
              await NativeSensorControl.removeAllListeners();
            }}
            detail={false}>
            <IonLabel className='TextAlign'>Test button 2</IonLabel>
          </IonItem> */}

          {/* <IonItem
            button
            onClick={async () => {
              await NativeSensorControl.on('discover', (discoveredSensor) => {
                console.log(discoveredSensor.macAddress);
              });

              await NativeSensorControl.startScanning();
            }}
            detail={false}>
            <IonLabel className='TextAlign'>Startscan</IonLabel>
          </IonItem>

          <IonItem
            button
            onClick={async () => {
              await NativeSensorControl.stopScanning();
              await NativeSensorControl.removeAllListeners();
            }}
            detail={false}>
            <IonLabel className='TextAlign'>Stopscan</IonLabel>
          </IonItem>

          <IonItem detail={false}>
            <IonLabel className='TextAlign'>{`${debug1.magneticFieldAccuracy} / ${debug1.magneticFieldValue}`}</IonLabel>
          </IonItem> */}

          <IonItem
            button
            onClick={async () => {
              updateArrayCache('testrecording2.csv', 'saved_recordings');
            }}
            detail={false}>
            <IonLabel className='TextAlign'>testbtn 3</IonLabel>
          </IonItem>

          <IonItem detail={false}>
            <IonLabel className='TextAlign'>{}</IonLabel>
          </IonItem>
        </IonList>
      </IonPopover>

      {/* Used for languageMenu dropdown.... */}
      <IonPopover
        class='my-custom-class'
        event={showLanguageMenu.event}
        isOpen={showLanguageMenu.showLanguageMenu}
        onDidDismiss={() => setShowLanguageMenu({ showLanguageMenu: false, event: undefined })}
        mode='ios'>
        <IonList className='languageMenu_List'>
          {languageMenuItems.map((languageMenuItem, id) => {
            let menuClassNames = '';
            if (languageMenuItem.status === 'disabled') {
              menuClassNames = 'disabled';
            } else if (languageMenuItem.selected === true) {
              menuClassNames = 'selected';
            }
            return (
              <IonItem key={languageMenuItem.id} detail={false} className={menuClassNames}>
                <IonIcon src={languageMenuItem.icon} />
                <IonLabel className='TextAlign'>{languageMenuItem.name}</IonLabel>
              </IonItem>
            );
          })}
        </IonList>
      </IonPopover>

      {/* Ion Modal to show Your Profile */}
      <IonModal isOpen={showUserInfo !== ''} class='userInfo-modal' onDidDismiss={() => setShowUserInfo('')}>
        {showUserInfo == 'YourProfile' && (
          <>
            {/* <YourProfile user={user!} Info={userInfo} /> */}
            <IonButton
              slot='icon-only'
              onClick={() => setShowUserInfo('')}
              fill='clear'
              className='userInfo-modal-close'>
              <IonIcon icon={closeCircleOutline} />
            </IonButton>
          </>
        )}
        {showUserInfo == 'YourSubscriptions' && (
          <>
            {/* <YourSubscriptions user={user!} userInfo={userInfo} /> */}
            <IonButton
              slot='icon-only'
              onClick={() => setShowUserInfo('')}
              fill='clear'
              className='userInfo-modal-close'>
              <IonIcon icon={closeCircleOutline} />
            </IonButton>

            <IonButton onClick={() => ''} fill='clear' className='go-to-billing-button' size='small'>
              <IonIcon icon={chevronForwardOutline} slot='end' className='button_Dropdown_Icon' />
              Go to billing
            </IonButton>
          </>
        )}
      </IonModal>

      <IonHeader mode='md'>
        <IonToolbar>
          {/* <IonSearchbar
            className='searchBar'
            value={searchText}
            onIonChange={(e) => onSearch(e)}
            showCancelButton='focus'
            animated
            disabled
            placeholder='Search for tags or filenames'></IonSearchbar> */}
          {backTarget && (
            <IonButtons slot='start'>
              <IonButton
                shape='round'
                onClick={() => {
                  if (unsavedChanges) {
                    presentAlert({
                      header: 'Unsaved changes',
                      message: 'You have unsaved changes. Are you sure you want to leave this page?',
                      cssClass: 'informativeAlert',
                      buttons: [
                        {
                          text: 'No',
                          role: 'cancel',
                          handler: () => {
                            console.log('Alert cancelled');
                          },
                        },
                        {
                          text: 'Yes',
                          role: 'confirm',
                          cssClass: 'destructiveAlertButton',
                          handler: () => {
                            goBack(backTarget);
                            console.log('Alert confirmed');
                          },
                        },
                      ],
                    });
                  } else {
                    goBack(backTarget);
                  }
                }}>
                <IonIcon icon={arrowBack} />
              </IonButton>
            </IonButtons>
          )}

          <IonButtons
            slot='primary'
            className='laguage_Buttons'
            onClick={(e: any) => {
              e.persist();
              setShowLanguageMenu({ showLanguageMenu: true, event: e });
            }}>
            <IonButton>
              <IonIcon icon={languageOutline} className='language_Buttons_Icon_Language' />
            </IonButton>
            <IonButton className='second_Language_Button'>
              <IonIcon icon={chevronDownOutline} className='button_Dropdown_Icon' />
            </IonButton>
          </IonButtons>
          <IonButtons slot='primary'>
            <IonButton
              className='user_Button'
              onClick={(e: any) => {
                e.persist();
                setShowUserMenu({ showUserMenu: true, event: e });
              }}>
              {user!.displayName}
              <IonIcon slot='end' icon={chevronDownOutline} className='button_Dropdown_Icon' />
            </IonButton>
          </IonButtons>
        </IonToolbar>

        {loading &&
          (progress !== undefined ? (
            <IonProgressBar value={progress}></IonProgressBar>
          ) : (
            <IonProgressBar type='indeterminate'></IonProgressBar>
          ))}
      </IonHeader>
    </>
  );
};

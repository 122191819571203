import './index.css';

import {
  IonActionSheet,
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonGrid,
  IonIcon,
  IonRow,
  IonText,
  IonThumbnail,
  IonToast,
  useIonAlert,
} from '@ionic/react';
import {
  checkmarkOutline,
  closeOutline,
  menuOutline,
  pencilOutline,
  speedometerOutline,
  trashOutline,
} from 'ionicons/icons';
import { useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import complexityIcon from '../../../assets/icons/complexityIcon.svg';
import { Exercise } from '../../../model';

// import required modules
import { useAuthContext } from '../../../contexts/authContext';
import { useExercises } from '../../../hooks/useExercises';

export interface IExerciseCardProps {
  exercise?: Exercise;
}

export const ExercisePreviewCard = ({ exercise }: IExerciseCardProps) => {
  const history = useHistory();
  const user = useAuthContext().state.userState?.user;
  const { getTumbnailURL, thumbnailURL, setStatusInactive } = useExercises(user?.uid);
  const [showActionSheet, setShowActionSheet] = useState(false);
  const [showErrorSetStatusInactive, setShowErrorSetStatusInactive] = useState(false);
  const [presentAlert] = useIonAlert();
  const noEasyAlternative =
    exercise?.EasyAlternative === undefined || exercise?.EasyAlternative === '' || exercise?.EasyAlternative === null;
  const noMediumAlternative =
    exercise?.MediumAlternative === undefined ||
    exercise?.MediumAlternative === '' ||
    exercise?.MediumAlternative === null;
  const noHardAlternative =
    exercise?.HardAlternative === undefined || exercise?.HardAlternative === '' || exercise?.HardAlternative === null;

  const pagination = {
    clickable: true,
    renderBullet: function (index: number, className: string) {
      return '<span class="' + className + '"></span>';
    },
  };

  // const getThumbnailURL = async () => {
  //   await getTumbnailURL(exercise!.thumbnailURL).then((url) => {
  //     return url;
  //   });
  // };

  const thumbnailPreview = useMemo(() => getTumbnailURL(exercise!.thumbnailURL), [exercise]);

  // useEffect(() => {
  //   getThumbnailURL();
  // }, [exercise]);

  // useEffect(() => {
  //   console.log('useEffect', url);
  //   if (url !== undefined) setIDontKnow(url.toString());
  // }, [url]);

  return (
    <>
      {exercise && (
        <>
          <IonToast
            isOpen={showErrorSetStatusInactive}
            onDidDismiss={() => setShowErrorSetStatusInactive(false)}
            message={`No exercise id for given exercise ${exercise.Name}, with id ${exercise.id}`}
            duration={2000}
          />

          <IonCard className='exercisePreviewCard'>
            <IonCardContent className='cardContent'>
              <IonGrid>
                <IonRow className=''>
                  <IonCol className='exercisePreviewCard_Thumbnail ion-align-self-center'>
                    <IonThumbnail>
                      <img
                        alt={exercise.Name}
                        src={thumbnailURL ? thumbnailURL : 'https://ionicframework.com/docs/img/demos/thumbnail.svg'}
                        onClick={() => history.push(`/exercises/exercise/${exercise.id}`)}
                      />
                    </IonThumbnail>
                  </IonCol>
                  <IonCol className='exercisePreviewCard_Content'>
                    <IonRow className='exercisePreviewCard_CardHeader'>
                      <IonCol
                        size='9'
                        className='exercisePreviewCard_HeaderText'
                        onClick={() => history.push(`/exercises/exercise/${exercise.id}`)}>
                        <IonRow>
                          <IonText color='primary' className='exercisePreviewCard_HeaderText'>
                            <strong>{exercise.Name}</strong>
                          </IonText>
                        </IonRow>
                        <IonRow>
                          <IonText className='exercisePreviewCard_SubtitleText'>
                            <small>
                              <strong>{exercise.Subtitle}</strong>
                            </small>
                          </IonText>
                        </IonRow>
                      </IonCol>
                      <IonCol size='3' className='ion-no-padding ion-text-center'>
                        <IonButton
                          onClick={() => setShowActionSheet(true)}
                          fill='clear'
                          className='actionButton'
                          size='small'>
                          <IonIcon icon={menuOutline} slot='icon-only' />
                        </IonButton>
                        <IonActionSheet
                          isOpen={showActionSheet}
                          onDidDismiss={() => setShowActionSheet(false)}
                          mode='ios'
                          buttons={[
                            {
                              text: 'Edit',
                              icon: pencilOutline,
                              handler: () => {
                                history.push(`/exercises/exercise/${exercise.id}`);
                              },
                            },
                            {
                              text: 'Delete',
                              role: 'destructive',
                              icon: trashOutline,
                              handler: () => {
                                if (exercise.id) {
                                  presentAlert({
                                    header: 'Destructive action',
                                    message: `Are you sure you want to delete the exercise ${exercise.Name} including all media?`,
                                    cssClass: 'destructiveAlert',
                                    buttons: [
                                      {
                                        text: 'Cancel',
                                        role: 'cancel',
                                        handler: () => {
                                          console.log('Alert cancelled');
                                        },
                                      },
                                      {
                                        text: 'OK',
                                        role: 'confirm',
                                        cssClass: 'destructiveAlertButton',
                                        handler: () => {
                                          setStatusInactive(exercise);
                                          console.log('Alert confirmed');
                                        },
                                      },
                                    ],
                                  });
                                } else {
                                  setShowErrorSetStatusInactive(true);
                                  console.log(
                                    `No exercise id for given exercise ${exercise.Name}, with id ${exercise.id}`,
                                  );
                                }
                              },
                            },

                            {
                              text: 'Cancel',
                              icon: closeOutline,
                              role: 'cancel',
                              handler: () => {
                                console.log('Cancel clicked');
                              },
                            },
                          ]}></IonActionSheet>
                      </IonCol>
                    </IonRow>
                    <IonRow className=''>
                      <IonCol
                        size='2'
                        className='ion-no-padding ion-text-center ion-align-items-center ion-align-self-center'>
                        <IonIcon icon={speedometerOutline} size='' className='horizontal-center' />
                      </IonCol>
                      <IonCol size='10'>
                        <IonText color=''>
                          <small>{exercise.Level}</small>
                        </IonText>
                      </IonCol>
                    </IonRow>
                    <IonRow className=''>
                      <IonCol
                        size='2'
                        className='ion-no-padding ion-text-center ion-align-items-center ion-align-self-center'>
                        <IonIcon
                          icon={complexityIcon}
                          className='horizontal-center'
                          size='small'
                          color='primary-grey200'
                        />
                      </IonCol>
                      <IonCol size='10'>
                        <IonText color=''>
                          <small>{exercise.Complexity}</small>
                        </IonText>
                      </IonCol>
                    </IonRow>
                    <IonRow className=''>
                      <IonCol size='4' className='border-right'>
                        <IonRow className='ion-justify-content-center'>
                          <IonText color='' className=''>
                            <small>
                              <strong>Easy Alt.</strong>{' '}
                            </small>
                          </IonText>
                        </IonRow>
                        <IonRow className='ion-justify-content-center'>
                          {exercise.Complexity === 'Easy' && (
                            <IonText color='' className='ion-text-center'>
                              <small>N.A.</small>
                            </IonText>
                          )}
                          {noEasyAlternative && exercise.Complexity !== 'Easy' && (
                            <IonIcon icon={closeOutline} className='horizontal-center' color='error600' size='small' />
                          )}
                          {!noEasyAlternative && (
                            <IonIcon
                              icon={checkmarkOutline}
                              size='small'
                              className='horizontal-center'
                              color='success'
                            />
                          )}
                        </IonRow>
                      </IonCol>
                      <IonCol size='4' className='border-right'>
                        <IonRow className='ion-justify-content-center'>
                          <IonText color='' className=''>
                            <small>
                              <strong>Med Alt.</strong>{' '}
                            </small>
                          </IonText>
                        </IonRow>
                        <IonRow className='ion-justify-content-center'>
                          {exercise.Complexity === 'Medium' && (
                            <IonText color='' className='ion-text-center'>
                              <small>N.A.</small>
                            </IonText>
                          )}
                          {noMediumAlternative && exercise.Complexity !== 'Medium' && (
                            <IonIcon icon={closeOutline} className='horizontal-center' color='error600' size='small' />
                          )}
                          {!noMediumAlternative && (
                            <IonIcon
                              icon={checkmarkOutline}
                              size='small'
                              className='horizontal-center'
                              color='success'
                            />
                          )}
                        </IonRow>
                      </IonCol>
                      <IonCol size='4' className=''>
                        <IonRow className='ion-justify-content-center'>
                          <IonText color='' className=''>
                            <small>
                              <strong>Hard Alt.</strong>{' '}
                            </small>
                          </IonText>
                        </IonRow>
                        <IonRow className='ion-justify-content-center'>
                          {exercise.Complexity === 'Hard' && (
                            <IonText color='' className='ion-text-center'>
                              <small>N.A.</small>
                            </IonText>
                          )}
                          {noHardAlternative && exercise.Complexity !== 'Hard' && (
                            <IonIcon icon={closeOutline} className='horizontal-center' color='error600' size='small' />
                          )}
                          {!noHardAlternative && (
                            <IonIcon
                              icon={checkmarkOutline}
                              size='small'
                              className='horizontal-center'
                              color='success'
                            />
                          )}
                        </IonRow>
                      </IonCol>
                    </IonRow>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCardContent>
          </IonCard>
        </>
      )}
    </>
  );
};
function useEffect(arg0: () => void, arg1: (Exercise | undefined)[]) {
  throw new Error('Function not implemented.');
}

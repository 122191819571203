import { IonButton, IonCol, IonContent, IonGrid, IonPage, IonRow, IonText } from '@ionic/react';
import 'firebase/firestore';

import { useHistory } from 'react-router';
import { ORYXHeader } from '../components/ui';
import { useAuthContext } from '../contexts/authContext';
import './Home.css';

export const HomePage: React.FC = () => {
  const user = useAuthContext().state.userState?.user;
  const role = useAuthContext().state.userState?.role;
  const history = useHistory();

  return (
    <IonPage id='main'>
      <IonContent fullscreen className='appBackground'>
        <ORYXHeader />
        <IonGrid>
          <IonRow>
            <IonCol size='12' className='ion-text-center ion-padding-top'>
              <IonText>
                <h5>Welcome {user?.displayName}</h5>
                <p>Your Uid: {user?.uid}</p>
                <p>Your permissions: {role}</p>
                {process.env.NODE_ENV === 'development' && <p> NODE_ENV = {process.env.NODE_ENV}</p>}
                {process.env.NODE_ENV === 'development' && (
                  <p> AUTH DOMAIN = {process.env.REACT_APP_FIREBASE_AUTH_DOMAIN}</p>
                )}
              </IonText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size='6' className='ion-text-center ion-padding-top'>
              <IonButton
                size='small'
                className='primary'
                onClick={() => {
                  history.push('/exercises');
                }}>
                Go to Exercises
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};
